<template>
    <div>
        <div class="card bg-light-gray mb-1 filter_card" v-show="flag.show_filter">
            <div class="card-body">
                <div class="row">
                    <div class="col">
                        <h5>Search Filter</h5>
                    </div>
                </div>
                <div class="form-row mt-1">
                    <div class="col-md-3">
                        <treeselect v-model="search.course_level_id" :options="courseLevelList" id="course_level_id" placeholder="Select Course Level" autocomplete="off"/>
                    </div>
                    <div class="col-md-3">
                        <treeselect v-model="search.country_id" :options="countryList" id="country_id" placeholder="Select Country" autocomplete="off"/>
                    </div>
                    <div class="col-md-3">
                        <input type="text" v-model="search.uni_ref_no" class="form-control" id="uni_ref_no" placeholder="Student ID">
                    </div>
                    <div class="col-md-3">
                        <input type="text" v-model="search.ucas_id" class="form-control" id="ucas_id" placeholder="UCAS ID">
                    </div>
                    
                    <!-- <div class="col-md-3">
                        <flat-pickr
                        id="edit_end_date"
                        v-model="search.start_date"
                        :config="configs.flatpickr2"
                        class="form-control"
                        placeholder="Select start date"/>
                    </div> -->
                </div>
                <div class="form-row mt-1">
                    <div class="col-md-3">
                        <treeselect v-model="search.course_type_id" :options="uniCourseTypeStatuses" id="course_type_id" placeholder="Select Course Type" autocomplete="off"/>
                    </div>
                    <div class="col-md-3">
                        <input type="text" v-model="search.name" class="form-control" id="name" placeholder="First name">
                    </div>
                    <div class="col-md-3">
                        <input type="text" v-model="search.last_name" class="form-control" id="last_name" placeholder="Last name">
                    </div>
                    <div class="col-md-3">
                        <div class="form-row">
                            <div class="col">
                                <button class="btn btn-primary btn-block cust_btn" @click="searchData()">Search</button>
                            </div>                            
                            <div class="col">
                                <!-- <button class="btn btn-primary btn-block" @click="searchData()"><i data-feather='search'></i> Search</button> -->
                                <button class="btn btn-outline-danger" @click="ClearSearchData()"><i data-feather='x'></i>Clear</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card agency-list-card">
            <div class="card-body">
                <div class="card_head">
                    <div class="row">
                        <div class="col-md-6">
                            <div v-if="!agent_branch" class="row">
                                <div class="col-md-6">
                                    <div class="p-1 border-radius-10 box-shadow-inset">
                                        <div class="d-flex">
                                            <div>
                                                List Ref: <span v-tooltip="'Submission List Ref'" class="badge badge-pill badge-light-success list_ref_badge" v-html="list_info.list_code"></span><br/>
                                                <span v-if="list_info.notify_agent_at" class="light_text mt-50 d-block">
                                                    <i class="fa fa-calendar mr-50"></i> <span v-tooltip="'Confirmed Date'" v-html="dDate(list_info.notify_agent_at)"></span>
                                                </span>
                                            </div>
                                            <div>
                                                <span v-tooltip="'PO Number'" class="badge badge-pill badge-light-blue ml-50" v-html="list_info.po_number"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 text-right">

                            <button
                                type="button"
                                @click="toggle_filter()"
                                class="btn btn-primary waves-effect waves-float waves-light cust_btn"
                            >
                                <i class="fa fa-filter mr-1"></i> Search Filter
                            </button>

                            <!-- <b-dropdown class="mr-2" v-if="totalStudentCount" text="Export As" variant="outline-primary">
                                <b-dropdown-item download :href="info.download_url">
                                    Excel                                            
                                </b-dropdown-item>
                            </b-dropdown> -->
                            <!-- <button @click="submitToInvoicing()" class="btn btn-success waves-effect waves-float waves-light mr-1">
                                Start Invoicing
                            </button> -->

                            <div class="mt-2">

                                <!-- <button :disabled="!flag.enable_agent_list_approval" @click="asListStatusUpdate" type="button" class="ml-5 btn  btn-primary" v-if="list_info.list_status == 122">
                                    Confirm Approved List
                                </button> -->
                                
                                <div v-tooltip="'List Status'" class="p-2 badge badge-pill badge-light-success ml-50" v-if="list_info.list_status == 123">
                                    Final Approved List Confirmed by Agent
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <h3 class="mt-2 mb-0">Confirmed Student List</h3>
                        </div>
                        <div class="col-md-6 text-right">
                            <a type="button" v-if="totalStudentCount" download :href="info.download_url" class="btn btn-secondary waves-effect waves-float waves-light cust_btn download_btn" v-tooltip="'Export as Excel'"><i class="fa fa-cloud-download"></i></a>
                        </div>
                    </div>
                </div>
     
                <div class="card">
                    <div class="form-row">
                        <div class="col-md-6 pb-50 mb-0 d-flex align-items-end mb-1">
                            <span class="badge badge-pill badge-light-primary hand counter_item mr-1" @click="getStatusWiseListData('')">
                                Total <span v-html="totalStudentCount"></span>
                            </span>
                            <!-- <small @click="getStatusWiseListData('')" class="cursor-pointer badge badge-pill badge-light-primary mr-1" v-html="'Total - ' + totalStudentCount"></small> -->
                            <span class="comms_line" v-if="studentList && studentList.length && totalCommissionAmount == 0" v-html="'Total Commission: 0'"></span>
                            <span class="comms_line" v-if="studentList && studentList.length && totalCommissionAmount > 0" v-html="'Total Commission: £ ' + integer(totalCommissionAmount)"></span>
                        </div>
                    </div>
                    <div class="table-responsive stu_tbl">
                        <table class="table line_tbl student_list_tbl"> 
                            <thead>
                                <tr>
                                    <th class="text-center">#</th>
                                    <th class="text-center" width="10%" v-if="check_branch > 0">Agent Code</th>
                                    <th class="text-center" width="10%" v-if="check_branch > 0">Branch Name</th>
                                    <th width="22%">
                                        Student Details
                                        <!-- <i v-if="studentNameAsc == 'student_unsort'" @click="getStatusWiseListData('student_unsort')" class="fa fa-sort cursor-pointer"></i>
                                        <i v-if="studentNameAsc == 'student_name'" @click="getStatusWiseListData('student_name')" class="fa fa-sort-asc cursor-pointer"></i>
                                        <i v-if="studentNameAsc == 'student_name_desc'" @click="getStatusWiseListData('student_name_desc')" class="fa fa-sort-desc cursor-pointer"></i> -->
                                    </th>
                                    <th width="30%">
                                        COURSE Details
                                    </th>
                                    <!-- <th width="8%" class="text-center px-1">Start Date</th>   -->
                                    <th width="8%" class="text-center">Intake</th>  
                                    <th width="7%" class="text-right">Fees</th>  
                                    <th width="7%" class="text-right">Commission</th>
                                    <th class="text-center" width="12%">
                                        PREVIOUSLY CLAIMED
                                    </th>
                                    <th class="text-right" width="10%">PAYABLE NOW</th>
                                    <th class="text-center">Remarks</th>
                                </tr>
                            </thead>
                            <tbody v-if="studentList && studentList.length > 0">
                                <template v-for="(item, index) in studentList">
                                    <!-- <tr :key="index" :class="showDomicile(item) ? 'bg-light-orange' : ''"> -->
                                    <!-- <tr :key="index" :class="checkNotInTerritory(item)"> -->
                                    <tr :key="index">
                                        <td class="text-center">
                                            <span  v-html="index + pagination.slOffset"></span>
                                        </td>
                                        <td class="text-center" v-if="check_branch>0">
                                            <strong v-if="cn(item, 'agent_branch', null)">
                                                {{ item.agent_branch.branch_banner_code }}
                                            </strong>
                                            <strong v-else-if="cn(item, 'agent_company', null)">{{ item.agent_company.agent_code }}</strong> 
                                        </td>
                                        <td class="text-center" v-if="check_branch>0"> <strong v-if="cn(item, 'agent_branch', null)">
                                                {{ item.agent_branch.branch_name }}
                                            </strong><strong v-else>-</strong></td>
                                        <td>
                                            <div class="d-flex justify-content-left align-items-center cursor-pointer">
                                                <!-- <div class="avatar-wrapper">
                                                    <div class="avatar mr-1">
                                                        <b-avatar variant="primary" :text="avatarText(item.student_name)"></b-avatar>
                                                    </div>
                                                </div> -->
                                                <div class="avatar-wrapper">
                                                    <div class="avatar mr-1 position-relative" v-tooltip="cn(item, 'country.name', '')">
                                                    <!-- <i v-if="item.recruitment_market_type" v-tooltip="'Not in Recruitment Territory'" class="fa fa-exclamation-triangle text-warning position-absolute top-0 start-100 translate-middle domicile_icon"></i> -->
                                                        <img v-if="item.country" :src="item.flag_url" height="32" width="32">
                                                    </div>
                                                </div>
                                                <!-- <span v-if="showDomicile(item)" v-tooltip="'Not in Recruitment Territory'" class="text-danger font-weight-bold p-50 mr-1" v-html="'!'"></span> -->
                                                <div class="d-flex flex-column">
                                                    <div class="text-dark">
                                                        <span class="font-weight-bold" style="font-size: 12px" v-html="item.student_name">
                                                        </span>
                                                    </div>
                                                    <small class="text-truncate align-middle cursor-pointer">
                                                        <span v-if="item.uni_ref_no" class="badge badge-pill badge-light-primary cust_badge mt-25" v-tooltip="'Student ID'">
                                                            <strong v-html="item.uni_ref_no"></strong>
                                                        </span>
                                                        <span v-if="item.ucas_id" class="badge badge-pill badge-light-secondary cust_badge mt-25" v-tooltip="'UCAS ID'">
                                                            <strong v-html="item.ucas_id"></strong>
                                                        </span>
                                                    </small>

                                                    <!-- <span class="ml-50" v-if="item.ucas_id && is_dispute_case(item)">
                                                        <i
                                                            class="fa fa-exclamation-triangle ml-25 text-warning"
                                                            aria-hidden="true"
                                                            v-tooltip="'Dispute Case Exists'"
                                                        ></i>
                                                    </span> -->

                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <span v-if="item.programme_name" class="align-middle cursor-pointer d-block" style="font-size: 13px">
                                                <span v-tooltip="item.programme_name" v-html="limit_str(item.programme_name, 55)"></span>
                                            </span>

                                            <small class="badge badge-pill badge-light-blue cust_badge" v-html="cn(item, 'course_level.name', '')" v-tooltip="'Course Level'"></small>
                                            <small class="badge badge-pill badge-light-warning cust_badge ml-50"
                                                v-if="cn(item, 'programme.course_type.code', '') == 'uni_course_type_medicine' || cn(item, 'programme.course_type.code', '') == 'uni_course_type_aviation' || cn(item, 'programme.course_type.code', '') == 'uni_course_type_distance_learning'"
                                                v-html="cn(item, 'programme.course_type.name', '')" v-tooltip="'Course Type'">
                                                </small>
                                            <span class="badge badge-pill badge-light-warning cust_badge ml-50" v-if="item.is_partner && item.partner_institute" v-html="item.partner_institute.short_name" v-tooltip="item.partner_institute.name"></span>
                                            <span class="badge badge-pill badge-light-warning font-size-10 ml-50" v-if="item.is_presessional" v-html="'Pre Sessional'" v-tooltip="'Pre Sessional'"></span>
                                        </td>
                                        <td class="px-1 text-center"> 
                                            <small class="text-truncate align-middle" v-if="item.intake" v-html="item.intake.name"></small><br>
                                        </td>

                                        <td class="px-1"> 
                                            <small class="text-truncate align-middle float-right" v-html="'£ ' + float2(item.tuition_fee)"></small><br>
                                        </td>
                                        <td class="px-1">
                                            <small class="text-truncate align-middle float-right" v-html="'£ ' + float2(item.commission_amount)"></small><br>
                                            <small class="emp_post text-muted text-truncate align-middle float-right" v-html="float2(item.current_commission_rate)+'%'"></small>
                                        </td>
                                        <td class="px-1"> 
                                            <small class="text-truncate align-middle float-right" v-if="item.previously_paid_commission" v-html="'£ ' + float2(item.previously_paid_commission)"></small>
                                            <small class="text-truncate align-middle float-right" v-else v-html="'-'"></small>
                                            <!-- <small class="emp_post text-muted text-truncate align-middle float-right" v-html="float2(item.previously_rate)+'%'"></small> -->
                                        </td>
                                        <td class="px-2">
                                            <small class="text-truncate align-middle float-right" v-html="'£ ' + float2(item.payable_commission)"></small><br>
                                        </td>
                                        
                                        <td class="remarks-column text-center">
                                            <i class="fa fa-info-circle ml-50 text-info fa-lg" v-if="item.dispute_remarks" v-tooltip="item.dispute_remarks"></i>
                                        </td>

                                    </tr>
                                </template>
                            </tbody>
                            <tbody v-else>
                                <tr>
                                    <th colspan="10" class="text-center no_info_th">
                                        <span v-if="studentList === null" >Processing...</span>
                                        <span v-else class="text-warning">
                                            <i class="fa fa-exclamation-triangle mr-50" aria-hidden="true"></i>
                                            Found no student data.
                                        </span>
                                    </th>
                                </tr>
                            </tbody>
                        </table>

                        <b-pagination
                            class="mt-1 mr-1"
                            align="right"
                            v-model="pagination.currentPage"
                            pills
                            :per-page="pagination.perPage"
                            :total-rows="pagination.totalRows"
                            @input="searchData"
                            >
                        </b-pagination>
                    </div>

                    <div class="my-2 mx-1 d-flex align-items-center justify-content-end confirmed_btn_part">

                        <div v-if="list_info.list_status == 122">
                            <div class="custom-control custom-checkbox d-inline mr-1">
                            <input type="checkbox" id="check-confimed-approval" class="custom-control-input" v-model="flag.enable_agent_list_approval"/>
                            <label for="check-confimed-approval" class="custom-control-label">
                                I accept the university approved list for commission
                            </label>
                            </div>
                        </div>

                        <button :disabled="!flag.enable_agent_list_approval" @click="asListStatusUpdate" type="button" class="btn  btn-primary" v-if="list_info.list_status == 122">
                            Confirm Approved List
                        </button>
                        <div v-tooltip="'List Status'" class="p-2 badge badge-pill badge-light-success ml-50" v-if="list_info.list_status == 123">
                            Final Approved List Confirmed by Agent
                        </div>

                    </div>

                </div>

            </div>
        </div>

        
     
        <div v-if="returningTotalStudentCount" class="card mt-2 mb-1">
            <div class="row pl-2">
                <div class="col">
                    <h3 class="mt-1">Returning Student List</h3>
                </div>
            </div>
            <div class="form-row pl-2">
                <div class="col-md-6 pb-50 mb-0 d-flex align-items-end">
                    <small @click="getStatusWiseListData('')" class="cursor-pointer badge badge-pill badge-light-primary mr-1" v-html="'Total - ' + returningTotalStudentCount"></small>
                    <b v-if="returningStudentList && returningStudentList.length && totalCommissionAmount == 0" v-html="'Total Commission: 0'"></b>
                    <b v-if="returningStudentList && returningStudentList.length && totalCommissionAmount > 0" v-html="'Total Commission: £ ' + integer(returningTotalCommissionAmount)"></b>
                </div>
                <div class="col-md-6 text-right">
                    <b-dropdown class="mr-2 mb-1" v-if="returningTotalStudentCount" text="Export As" variant="outline-primary">
                        <b-dropdown-item download :href="info.returning_download_url">
                            Excel                                            
                        </b-dropdown-item>
                    </b-dropdown>
                </div>
            </div>
            <div class="card table-responsive p-0">
                <table class="table"> 
                    <thead class="thead-light">
                        <tr>
                            <th class="px-50 text-center">#</th>
                            <th class="px-1" width="22%">
                                Student Details
                                <!-- <i v-if="studentNameAsc == 'student_unsort'" @click="getStatusWiseListData('student_unsort')" class="fa fa-sort cursor-pointer"></i>
                                <i v-if="studentNameAsc == 'student_name'" @click="getStatusWiseListData('student_name')" class="fa fa-sort-asc cursor-pointer"></i>
                                <i v-if="studentNameAsc == 'student_name_desc'" @click="getStatusWiseListData('student_name_desc')" class="fa fa-sort-desc cursor-pointer"></i> -->
                            </th>
                            <th class="px-1" width="30%">
                                COURSE Details
                            </th>
                            <th width="8%" class="text-center px-1">Start Date</th>  
                            <th width="7%" class="text-right px-1">Fees</th>  
                            <th width="7%" class="text-right px-1">Commission</th>
                            <th class="px-1 text-right" width="12%">PREVIOUSLY CLAIMED</th>
                            <th class="px-1 text-right" width="10%">PAYABLE NOW</th>
                        </tr>
                    </thead>
                    <tbody v-if="returningStudentList && returningStudentList.length > 0">
                        <template v-for="(item, index) in returningStudentList">
                            <!-- <tr :key="index" :class="showDomicile(item) ? 'bg-light-orange' : ''"> -->
                            <!-- <tr :key="index" :class="checkNotInTerritory(item)"> -->
                            <tr :key="index">
                                <td class="px-50 text-center">
                                    <span  v-html="index + pagination.slOffset"></span>
                                </td>
                                <td class="px-1">
                                    <div class="d-flex justify-content-left align-items-center cursor-pointer">
                                        <!-- <div class="avatar-wrapper">
                                            <div class="avatar mr-1">
                                                <b-avatar variant="primary" :text="avatarText(item.student_name)"></b-avatar>
                                            </div>
                                        </div> -->
                                        <div class="avatar-wrapper">
                                            <div class="avatar mr-1 position-relative" v-tooltip="cn(item, 'country.name', '')">
                                            <!-- <i v-if="item.recruitment_market_type" v-tooltip="'Not in Recruitment Territory'" class="fa fa-exclamation-triangle text-warning position-absolute top-0 start-100 translate-middle domicile_icon"></i> -->
                                                <img v-if="item.country" :src="item.flag_url" height="32" width="32">
                                            </div>
                                        </div>
                                        <!-- <span v-if="showDomicile(item)" v-tooltip="'Not in Recruitment Territory'" class="text-danger font-weight-bold p-50 mr-1" v-html="'!'"></span> -->
                                        <div class="d-flex flex-column">
                                            <div class="text-dark">
                                                <span class="font-weight-bold" style="font-size: 12px" v-html="item.student_name">
                                                </span>
                                            </div>
                                            <small class="text-truncate align-middle cursor-pointer">
                                                <span style="font-size: 100%" class="badge badge-pill badge-light-primary font-size-10" v-tooltip="'Student ID'">
                                                    <strong v-html="item.uni_ref_no"></strong>
                                                </span>
                                            </small>
                                        </div>
                                    </div>
                                </td>
                                <td class="px-1">
                                    <span v-if="item.programme_name" class="align-middle cursor-pointer d-block" style="font-size: 13px">
                                        <span v-tooltip="item.programme_name" v-html="limit_str(item.programme_name, 55)"></span>
                                    </span>

                                    <small class="badge badge-pill badge-light-blue font-size-10" v-html="cn(item, 'course_level.name', '')" v-tooltip="'Course Level'"></small>
                                    <small class="badge badge-pill badge-light-warning font-size-10 ml-50"
                                        v-if="cn(item, 'programme.course_type.code', '') == 'uni_course_type_medicine' || cn(item, 'programme.course_type.code', '') == 'uni_course_type_aviation' || cn(item, 'programme.course_type.code', '') == 'uni_course_type_distance_learning'"
                                        v-html="cn(item, 'programme.course_type.name', '')" v-tooltip="'Course Type'">
                                        </small>
                                    <span class="badge badge-pill badge-light-warning font-size-10 ml-50" v-if="item.is_partner && item.partner_institute" v-html="item.partner_institute.short_name" v-tooltip="item.partner_institute.name"></span>
                                    <span class="badge badge-pill badge-light-warning font-size-10 ml-50" v-if="item.is_presessional" v-html="'Pre Sessional'" v-tooltip="'Pre Sessional'"></span>
                                </td>
                                <td class="px-1 text-center"> 
                                    <small class="text-truncate align-middle" v-if="item.intake" v-html="item.intake.name"></small><br>
                                </td>

                                <td class="px-1"> 
                                    <small class="text-truncate align-middle float-right" v-html="'£ ' + float2(item.tuition_fee)"></small><br>
                                </td>
                                <td class="px-1">
                                    <small class="text-truncate align-middle float-right" v-html="'£ ' + float2(item.commission_amount)"></small><br>
                                    <small class="emp_post text-muted text-truncate align-middle float-right" v-html="float2(item.current_commission_rate)+'%'"></small>
                                </td>
                                <td class="px-1"> 
                                    <small class="text-truncate align-middle float-right" v-if="item.previously_paid_commission" v-html="'£ ' + float2(item.previously_paid_commission)"></small>
                                    <small class="text-truncate align-middle float-right" v-else v-html="'-'"></small>
                                    <!-- <small class="emp_post text-muted text-truncate align-middle float-right" v-html="float2(item.previously_rate)+'%'"></small> -->
                                </td>
                                <td class="px-1">
                                    <small class="text-truncate align-middle float-right" v-html="'£ ' + float2(item.payable_commission)"></small><br>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <th colspan="9" class="text-center no_info_th">
                                <span v-if="returningStudentList === null" >Processing...</span>
                                <span v-else class="text-warning">
                                    <i class="fa fa-exclamation-triangle mr-50" aria-hidden="true"></i>
                                    Found no student data.
                                </span>
                            </th>
                        </tr>
                    </tbody>
                </table>

                <b-pagination
                    class="mt-1 mr-1"
                    align="right"
                    v-model="paginationCustom.currentPage"
                    pills
                    :per-page="paginationCustom.perPage"
                    :total-rows="paginationCustom.totalRows"
                    @input="searchData"
                    >
                </b-pagination>
            </div>
        </div>

        <!-- Remarks here -->
       <!-- <student-remarks :list_id="search.list_id"/> -->

       


    </div>
</template>

<script>

import feather from 'feather-icons';
import { BPagination, BDropdown, BDropdownItem } from 'bootstrap-vue';
import Treeselect from '@riophae/vue-treeselect';
import SidebarForm from '../SidebarForm';
import flatPickr from 'vue-flatpickr-component';
// import { EditIcon, MinusSquareIcon } from 'vue-feather-icons';
import swal from 'bootstrap-sweetalert';
import Swal from 'sweetalert2'
import Hashids from 'hashids';
import moment from 'moment';
// import StudentRemarks from './Partials/StudentRemarks.vue';


export default{
    name: 'ConfirmStudentList',
    props: ['list_info', 'ss_counter','check_branch'],
    components:{
        BPagination,
        BDropdown, BDropdownItem,
        Treeselect,
        SidebarForm,
        flatPickr,
        // StudentRemarks,
        // MoreVerticalIcon
        // EditIcon,
        // MinusSquareIcon,
    },
    data:()=>{
        return {
            studentNameAsc: 'student_unsort',
            courseNameAsc: 'course_unsort',
            totalStudentCount: 0,
            totalCommissionAmount: 0,
            returningTotalStudentCount: 0,
            studentList: null,
            returningStudentList: null,
            regionList: [],
            regionCountryList: [],
            statusTreeSlectList: [],
            statusList: [],
            countryList: [],
            agentAcceptStatusList: [],
            inDeadline: true,
            disabled: false,
            intakeItem: {},
            statusGroupList: [],
            studentListItem: {},
            flag:{
                show_agent_form:false,
                show_filter:false,
                show_modal_form:false,
                enable_agent_list_approval: false
            },
            form:{
                send_invitation:{
                    checked_all: false,
                    agent_ids:[]
                },
                agent_status_update:{
                    agent_id:'',
                    new_status: ''
                }
            },
            search: {
                course_level_id: null,
                course_type_id: null,
                country_id: null,
                uni_ref_no: '',
                name: '',
                last_name: '',
                start_date: '',
                submission_status_id: null,
                intake_id: null,
                list_id: null,
            },
            list:{
                agent_statuses:[{
                    id: 'inactive',
                    label: 'Inactive',
                    btn_color: 'outline-danger',
                    text_color: 'text-danger'
                }, {
                    id: 'pending',
                    label: 'Pending',
                    btn_color: 'outline-warning',
                    text_color: 'text-warning'
                },{
                    id: 'approved',
                    label: 'Approved',
                    btn_color: 'outline-success',
                    text_color: 'text-success'
                }]
            },
            editItem: {},
            configs: {
                flatpickr: {
                    dateFormat: 'd M Y',
                    maxDate: new Date()
                },        
                flatpickr2: {
                    dateFormat: 'd M Y',
                    mode: "range"
                }        
            },
            courseLevelList: [],
            courseTitleList: [],
            info: {
                download_url:'#',
                returning_download_url:'#',
            },
        };
    },
    watch: {
        'search.region_id': function (value) {
            this.countryList = this.getCountryList(value)
            // this.getListData()
        },
        'search.country_id': function () {
            // this.getListData()
        },
        'search.name': function () {
            // this.getListData()
        }
    },
    methods:{
        agt_accept_status_color: function(row){

            var ref=this;
            const status =  ref.agentAcceptStatusList.find(item=>{
                if (item.id == row.agent_accept_status) {
                    return item
                }
            });

            if (!status) {
                return 'secondary'
            } else {
                return status.color
            }

        },
        agt_accept_status_name: function(row){

            var ref=this;
            const status =  ref.agentAcceptStatusList.find(item=>{
                if (item.id == row.agent_accept_status) {
                    return item
                }
            });
            // console.log('row', row)

            if (!status) {
                return '-'
            } else {
                return status.name
            }

        },
        agentAcceptStatusUpdate: function(item, dropdown){

            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger ml-2'
                },
                buttonsStyling: false
            })

            let level = 'Please specify reason';
            let placeholder = 'Enter your specify reason';
            let html_input = '<div class="form-group"><label for="usr">'+ level +':</label><input id="swal_input" type="text" class="form-control" placeholder="'+placeholder+'"></div>';

            swalWithBootstrapButtons.fire({
                title: 'Agent Accept Status!',
                html: html_input,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                preConfirm: () => {
                    return [
                        document.getElementById('swal_input').value
                    ]
                }
                // reverseButtons: true
            }).then((result) => {
                if (result.isConfirmed) {
     
                    this.agentAcceptStatusUpdateConfirm(item, dropdown, result.value[0])

                }
            })
        },
        agentAcceptStatusUpdateConfirm: async function(item, dropdown, swal_input){
            var ref=this;
            var jq=ref.jq();

            try {
                const params = Object.assign({}, item, { swal_input: swal_input, agent_accept_status: dropdown.id, code: dropdown.code})
                var res = await jq.post(ref.url('api/v1/agent/ajax/agent_student_agent_accept_status_update'), params);
                this.getListData();
                swal("Agent Accept Status Updated", res.msg, "success")

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
        },
        ClearSearchData () {
            this.search.course_level_id = null
            this.search.course_type_id = null
            this.search.country_id = null
            this.search.uni_ref_no = ''
            this.search.name = ''
            this.search.last_name = ''
            this.search.start_date = ''
            this.search.submission_status_id = null
            this.search.intake_id = null
            this.searchData()
        },
        check_all_invitaion: function(check_all=false){

            var ref=this;
            var invitation_from=ref.form.send_invitation;

            if(check_all){

                if(invitation_from.checked_all){
                   ref.studentList.forEach(row=>{
                       invitation_from.agent_ids.push(row.id);
                   });
                }else  invitation_from.agent_ids=[];

            }else{
                
                if(invitation_from.agent_ids.length==ref.studentList.length){
                    invitation_from.checked_all=true;
                }else invitation_from.checked_all=false;
            }

        },
        async searchData () {
            this.getListData()
            this.getReturningListData()
            this.gen_download_url();
            this.gen_returning_download_url();
            
        },
        gen_download_url: function () {
            var ref=this;
            var jq=this.jq();
            this.search.auth_id = ref.store_auth_user.id
            // var qstr = new URLSearchParams(this.search).toString()
            var hash_ids  = new Hashids('sams_pay_export_hash_salt', 30);
            var institute_id = ref.store_auth_user.institute_id;
            // ref.info.download_url = ref.url('download/confirmed_student_list_from_agent_side/' + hash_ids.encode(institute_id)+ '?'+ qstr);
            ref.info.download_url = ref.url('download/export_agent_confirmed_students_list/' + hash_ids.encode(institute_id));
            var search_query = jq.param(this.search)
            ref.info.download_url += '?' + search_query
        },
        gen_returning_download_url: function () {
            var ref=this;
            var jq=this.jq();
            this.search.auth_id = ref.store_auth_user.id
            this.search.download_type = 'returning'
            // var qstr = new URLSearchParams(this.search).toString()
            var hash_ids  = new Hashids('sams_pay_export_hash_salt', 30);
            var institute_id = ref.store_auth_user.institute_id;
            ref.info.returning_download_url = ref.url('download/export_returning_agent_confirmed_students_list/' + hash_ids.encode(institute_id));
            var search_query = jq.param(this.search)
            ref.info.returning_download_url += '?' + search_query
        },
        toggle_agent_form: function(){
            this.flag.show_agent_form=!this.flag.show_agent_form;
        },
        getIntakeById: async function(){
            var ref=this;
            var jq=ref.jq();

            if (this.search.intake_id != null) {
                try {
                    ref.wait_me('.agency-list-card');
                    const params = Object.assign({}, this.search)
                    var res = await jq.get(ref.url('api/v1/agent/ajax/get_intake_by_id'), params);
                    ref.intakeItem = res.data.intake

                } catch (err) {
                    // ref.alert(ref.err_msg(err), 'error');
                }
                finally {
                    ref.wait_me('.agency-list-card', 'hide');
                }
            } else {
                this.getListData()
            }
        },
        checkStudentListSubmitted: async function(){

            var ref=this;
            var jq=ref.jq();

            try {
                const params = Object.assign({}, this.search)
                var res = await jq.get(ref.url('api/v1/agent/ajax/check_student_list_submitted'), params);
                ref.studentListItem = res.data.data
            } catch (err) {
                // ref.alert(ref.err_msg(err), 'error');
            }
        },
        getListData: async function(){

            var ref=this;
            var jq=ref.jq();

            try {
                ref.wait_me('.agency-list-card');
                const params = Object.assign({}, { page: this.pagination.currentPage, per_page: this.pagination.perPage },  this.search)
                var res = await jq.get(ref.url('api/v1/agent/ajax/get_confirm_agent_student_list_v2'), params);
                this.totalCommissionAmount = res.data.total_commission
                this.totalStudentCount = res.data.total_students
                if (res.data.students) {
                    if (res.data.students.data && res.data.students.data.length) {
                        this.studentList = res.data.students.data.map(item => {
                            item.domicile = item.domicile == 1 ? true : false
                            item.approval_status = item.approval_status ? Object.assign({}, item.approval_status, JSON.parse(item.approval_status.data)) : ''
                            return Object.assign({}, item)
                        });
                    } else {
                        this.studentList = []
                    }
                } else {
                    this.studentList = []
                }
                this.paginationData(res.data.students)

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
            finally {
                ref.wait_me('.agency-list-card', 'hide');
            }
        },
        getReturningListData: async function(){

            var ref=this;
            var jq=ref.jq();

            try {
                ref.wait_me('.agency-list-card');
                const params = Object.assign({}, { page: this.paginationCustom.currentPage, per_page: this.paginationCustom.perPage },  this.search)
                var returning_res = await jq.get(ref.url('api/v1/agent/ajax/get_returning_confirm_agent_student_list'), params);
                this.returningTotalCommissionAmount = returning_res.data.total_commission
                this.returningTotalStudentCount = returning_res.data.total_students
                if (returning_res.data.students) {
                    if (returning_res.data.students.data && returning_res.data.students.data.length) {
                        this.returningStudentList = returning_res.data.students.data.map(item => {
                            item.domicile = item.domicile == 1 ? true : false
                            item.approval_status = item.approval_status ? Object.assign({}, item.approval_status, JSON.parse(item.approval_status.data)) : ''
                            return Object.assign({}, item)
                        });
                    } else {
                        this.returningStudentList = []
                    }
                } else {
                    this.returningStudentList = []
                }
                this.paginationCustomData(returning_res.data.students)

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
            finally {
                ref.wait_me('.agency-list-card', 'hide');
            }
        },
        submitAllStudent: function(){
            // this.$refs.invitation_confirmation.show();
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger ml-2'
                },
                buttonsStyling: false
            })

            swalWithBootstrapButtons.fire({
                // title: 'Agent Student Submission',
                title: 'Student Submission To ' + this.intakeItem.name + ' List',
                html: "Do you want to submit the students to this list?",
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                reverseButtons: true
            }).then((result) => {
                if (result.isConfirmed) {
                    this.submitAllStudentConfirm()
                }
            })
        },
        submitAllStudentConfirm: async function(){

            var ref=this;
            var jq=ref.jq();

            try {
                ref.wait_me('.agency-list-card');
                const params = Object.assign({}, { intake_id: this.search.intake_id })
                var res = await jq.post(ref.url('api/v1/agent/ajax/submit_agent_all_student'), params);
                this.getListData()
                Swal.fire({
                    position: 'top-center',
                    icon: 'success',
                    title: res.msg,
                    showConfirmButton: false,
                    timer: 1500
                })

            } catch (err) {
                // ref.alert(ref.err_msg(err), 'error');
            }
            finally {
                ref.wait_me('.agency-list-card', 'hide');
            }
        },
        agent_status: function(row){

            var ref=this;
            return ref.list.agent_statuses.find(each=>{
                return each.id==row.status;
            });

        },
        update_agent_status: async function(){

            this.$refs.agent_status_confirmation.hide();

            var ref=this;
            var jq=this.jq();
            var url=ref.url('api/v1/university/ajax/update_agent_status');

            try{

                ref.wait_me('.agency-list-card');
                var res=await jq.post(url, ref.form.agent_status_update);

                ref.form.agent_status_update.agent_id='';
                ref.form.agent_status_update.new_status='';
                ref.alert(res.msg);
                ref.getListData();

            }catch(err){
                ref.alert(ref.err_msg(err), 'error')
            }finally{
                ref.wait_me('.agency-list-card', 'hide');
            }

        },
        send_single_mail: function(agent_id){

            var ref=this;
            ref.form.send_invitation.checked_all=false;
            ref.form.send_invitation.agent_ids.push(agent_id);
            ref.invitation_confirmation();

        },
        getCountryList: function (regionId) {
            if (regionId) {
                const regionCountryList = this.regionCountryList.filter(item => item.region_id == regionId)
                const countryMappingData = regionCountryList.map(item => {
                    const countryObj = this.$store.state.site.countries.find(country => country.id == item.country_id)
                    const countryData = {
                        id: countryObj.id,
                        label: countryObj.name,
                    }
                    return Object.assign({}, countryData)
                })
                return countryMappingData
            }
        },
        toggle_filter : function(){
            this.flag.show_filter=!this.flag.show_filter;
        },
        getCourseLevelList: async function(){

            var ref=this;
            var jq=ref.jq();
            try {
                var res = await jq.get(ref.url('api/v1/agent/ajax/get_uni_course_level_list'));
                this.courseLevelList = res.data.list.map(item => {
                    return {
                        id: item.id,
                        label: item.name
                    }
                });
                this.courseTitleList = res.data.list.map(item => {
                    return {
                        id: item.id,
                        label: item.name
                    }
                });
                // console.log('this.courseLevelList', res.data.list)

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
        },
        toggle_modal_form: function(){
            this.flag.show_modal_form = !this.flag.show_modal_form;
        },
        editData: function (item) {
            this.editItem = JSON.parse(JSON.stringify(item))
            this.editItem.dob = moment(this.editItem.dob).format("DD MMM YYYY");
            this.flag.show_modal_form = !this.flag.show_modal_form;
        },
        deleteFromIntake: function (item) {
            this.editItem = item
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger ml-2'
                },
                buttonsStyling: false
            })

            swalWithBootstrapButtons.fire({
                title: 'Remove From Intake',
                html: "Are you sure do you want to add to intake?",
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                reverseButtons: true
            }).then((result) => {
                if (result.isConfirmed) {
                    this.deleteFromConfirm(item)
                }
            })
        },
        deleteFromConfirm: async function(item){

            var ref=this;
            var jq=ref.jq();

            try {
                var res = await jq.post(ref.url('api/v1/agent/ajax/delete_from_intake_agent_student'), item);
                this.getListData()
                Swal.fire({
                    position: 'top-center',
                    icon: 'success',
                    title: res.msg,
                    showConfirmButton: false,
                    timer: 1500
                })

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
        },
        updateFormData: async function () {

            var ref=this;
            var jq=ref.jq();
            try{
                ref.wait_me('.wait_me_edit_form');
                var res = await jq.post(ref.url('api/v1/agent/ajax/update_agent_student_info'), ref.editItem);
                this.getListData()
                this.toggle_modal_form()
                // ref.alert(res.msg);
                Swal.fire({
                    position: 'top-center',
                    icon: 'success',
                    title: res.msg,
                    showConfirmButton: false,
                    timer: 1500
                })
            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
            finally {
                ref.wait_me('.wait_me_edit_form', 'hide');
            }
        },
        getStatusList: async function () {
            var ref=this;
            var jq=ref.jq();
            try {
                const params = { group_code: 'agent_student_submission_statuses' }
                var res = await jq.get(ref.url('api/v1/agent/ajax/get_statuses_by_groups'), params);
                this.statusTreeSlectList = res.data.statuses.map(item => {
                    return {
                        id: item.id,
                        label: item.name
                    }
                })
                // console.log('res.data.statuses', this.statusTreeSlectList)
                this.statusList = res.data.statuses.map(item => {
                    return Object.assign({}, item, JSON.parse(item.data))
                })
            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
        },
        getStatusesByGroup: async function(){

            var ref=this;
            var jq=ref.jq();
            try {
                var res = await jq.get(ref.url('api/v1/agent/ajax/get_status_groups_with_statuses'));
                this.statusGroupList = res.data.status_groups
                // console.log('this.statusGroupList', this.statusGroupList)
                // this.ioStatuses = res.data
            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
        },
        asListStatusUpdate: async function(){

            var ref=this;
            var jq=ref.jq();
            
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger ml-2'
                },
                buttonsStyling: false
            })

            let html_input = `Are you sure you want to confirm the Approved List sent by the University?`;

            swalWithBootstrapButtons.fire({
                title: 'Confirm Approved List',
                html: html_input,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
            }).then(async (result)=>{

                if(result.isConfirmed){
                    ref.wait_me('.agency-list-card');
                    await ref.asListStatusUpdateConfirm();
                    ref.wait_me('.agency-list-card','hide');
                }

            });

        },
        asListStatusUpdateConfirm: async function(){
            var ref=this;
            var jq=ref.jq();

            try {
                const params = Object.assign({}, { list_id: this.list_info.id})
                var res = await jq.post(ref.url('api/v1/agent/ajax/agent_student_list_confirm'), params);
                
                swal("Status Update", res.msg, "success")
                ref.$emit('updateAgentStudentList')
                
            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
        },
        is_dispute_case: function(item){
            return (item.duplicate_exists && item.dispute_status > 0 && item.dispute_status < 11);
        }
    },
    computed: {
        store_countries: function(){
            
            return this.$store.state.site.countries

        },
        treeselect_countries: function(){
            return this.store_countries.map(each=>{
                return {
                    id: each.id,
                    label: each.name
                }
            });
        },
        store_auth_user:function(){
            return this.cn(this.$store.state, 'site.auth_user', null);
        },
        uniCourseTypeStatuses: function() {
            const statusGroup = this.statusGroupList.find(item => item.code == 'uni_course_statuses')
            if (statusGroup) {
                return statusGroup.statuses.map(item => {
                        const myData = {
                            label: item.name,
                        }
                        return Object.assign({}, item, myData, JSON.parse(item.data))
                    })
            } else {
                return []
            }
        },
        sub_agent_status:function(){
            return this.cn(this.$store.state, 'site.sub_agent_status', null);
        },
        agent_branch: function(){
            return this.cn(this.$store.state, 'site.agent_branch', null);
        }
    },
    created: async function(){
        this.setup_urls();
        
        this.search.list_id = this.hash_id(this.$route.params.list_id, false)[0]
        this.search.intake_id = this.hash_id(this.$route.params.intake_id, false)[0]
            
        const countries = await this.countries()
        this.countryList = countries.map(item => {
            return {
                id: item.id,
                label: item.name
            }
        })
        
        this.gen_download_url();
        this.gen_returning_download_url();
    },
    mounted: function(){
        this.getIntakeById();
        // this.checkStudentListSubmitted();
        this.getListData();
        this.getReturningListData();
        // this.getRegionList()
        // this.getRegionCountryList()
        this.getCourseLevelList()
        this.getStatusList()
        feather.replace();
        this.getStatusesByGroup()
    }
}
</script>
<style type="text/css">
    .badge.badge-light-blue {
        background-color: rgba(51, 51, 255, 0.12);
        color: #3333ff !important;
    }        
    .badge.badge-light-declined {
        background-color: rgba(255, 51, 0, 0.12);
        color: #ff3300 !important;
    }
    .table th, .table td {
         padding: 0.72rem 0rem; 
        vertical-align: middle;
    }
    .btn-sm, .btn-group-sm > .btn {
        padding: 0.486rem .7rem !important; 
    }

    .sams-pay-university .agency-list .app-user-list .bs-stepper .agency-list-card .counter_item.badge-light-primary {
        color: #6966af !important;
    }
    .sams-pay-university .agency-list .app-user-list .bs-stepper .agency-list-card .counter_item {
        font-size: .75rem;
        padding: 4px 8px;
        border-radius: .25rem;
        background: #fff;
        letter-spacing: .3px;
        font-weight: 600;
        margin: 0 0 0 0;
        align-items: center;
        width: -moz-max-content;
        width: max-content;
        line-height: 16px;
        border: 1px solid #d8e2e7;
    }
    .sams-pay-university .agency-list .app-user-list .bs-stepper .agency-list-card .counter_item span {
        border-radius: .25rem;
        text-align: center;
        color: #fff;
        margin-left: 8px;
        padding: 2px 8px;
    }
    .sams-pay-university .agency-list .app-user-list .bs-stepper .agency-list-card .counter_item.badge-light-primary span {
        background: #6966af !important;
    }
    .sams-pay-university .agency-list .app-user-list .bs-stepper .agency-list-card .comms_line {
        top: -3px;
        position: relative;
    }
    .agency-list .app-user-list .bs-stepper .agency-list-card .confirmed_btn_part{
        margin-bottom: 75px !important;
    }
</style>
