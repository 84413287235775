<template>
<section class="dashboard">
    <main-layout>
        <div class="content-header row">
            <div class="content-header-left col-md-9 col-12 mb-2">
                <div class="row breadcrumbs-top">
                    <div class="col-12">
                        <!-- <h2 class="content-header-title float-left mb-0">Unassigned Students</h2> -->
                        <h5 class="content-header-title float-left mb-0" style="margin-top: 5px">Assign Student</h5>
                        <div class="breadcrumb-wrapper">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><router-link to="/">Home</router-link></li>
                                <!-- <li class="breadcrumb-item"><router-link to="/">Submit List</router-link></li> -->
                                <li class="breadcrumb-item"><router-link to="/submit-to-university">Intake List</router-link></li>
                                <li class="breadcrumb-item"><router-link :to="'/student-list/'+$route.params.intake_hashed_id"><span v-html="cn(info, 'intake.name', 'Loading...')"></span></router-link></li>
                                <li class="breadcrumb-item active">Add Student to List</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card agency-list-card">
            <div class="card-body p-0">
                <div class="row p-2">
                    <div class="col-md-6">
                        <h3>Add to Intake</h3>
                    </div>
                    <div class="col-md-6 text-right" v-show="inDeadline">

                        <!-- <b-dropdown class="mr-2" text="Export As" variant="outline-primary">
                            <b-dropdown-item download :href="info.download_url">
                                Excel                                            
                            </b-dropdown-item>
                        </b-dropdown>-->

                        <button v-show="form.add_to_intake.student_ids.length" @click="addToIntakeAllStudent()" class="btn btn-primary waves-effect waves-float waves-light mr-1">
                            <users-icon size="1.5x" class="custom-class"></users-icon>
                            Add Selected
                        </button>

                        <button type="button" @click="toggle_filter()" class="btn btn-primary waves-effect waves-float waves-light"><i data-feather='filter'></i></button>

                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <div class="alert alert-warning mt-1 alert-validation-msg" role="alert" v-show="!inDeadline">
                            <div class="alert-body">
                                <p class="text-center">You shall be allowed to submit your list for commission between <strong>{{ dDate(intakeItem.start_date) }}</strong> - <strong>{{ dDate(intakeItem.end_date) }}</strong>.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-row mt-1 mb-2  pl-2 pr-2"  v-show="flag.show_filter">
                    <div class="col-md-3">
                        <treeselect v-model="search.course_level_id" :options="courseLevelList" id="course_level_id" placeholder="Select Course Level" autocomplete="off"/>
                    </div>
                    <div class="col-md-3">
                        <treeselect v-model="search.country_id" :options="countryList" id="country_id" placeholder="Select Country" autocomplete="off"/>
                    </div>
                    <div class="col-md-3">
                        <treeselect v-model="search.submission_status_id" :options="statusTreeSlectList" id="submission_status_id" placeholder="Select status" autocomplete="off"/>
                    </div>
                    <div class="col-md-3">
                        <input type="text" v-model="search.uni_ref_no" class="form-control" id="uni_ref_no" placeholder="Reference">
                    </div>
                </div>

                <div class="form-row mt-1 mb-2  pl-2 pr-2"  v-show="flag.show_filter">
                    <div class="col-md-3">
                        <flat-pickr
                        id="edit_end_date"
                        v-model="search.start_date"
                        :config="configs.flatpickr2"
                        class="form-control"
                        placeholder="Select start date"/>
                    </div>
                    <div class="col-md-3">
                        <input type="text" v-model="search.name" class="form-control" id="name" placeholder="First name">
                    </div>
                    <div class="col-md-3">
                        <input type="text" v-model="search.last_name" class="form-control" id="last_name" placeholder="Last name">
                    </div>
                    <div class="col-md-3">
                        <div class="form-row">
                            <div class="col">
                                <button class="btn btn-outline-danger btn-block" @click="ClearSearchData()"><i data-feather='x'></i> Clear</button>
                            </div>                            
                            <div class="col">
                                <!-- <button class="btn btn-primary btn-block" @click="searchData()"><i data-feather='search'></i> Search</button> -->
                                <button class="btn btn-primary btn-block" @click="searchData()">Search</button>
                            </div>
                        </div>
                    </div>
                </div>

                
                <div class="row" v-if="this.editItem">
                    <div class="col-md-6"></div>
                    <div class="col-md-6">
                        <sidebar-form 
                            :show_sidebar="flag.show_modal_form"
                            v-on:toggle-sidebar="toggle_modal_form()"
                            title="Edit Student"
                            >
                            <div class="mb-1">
                                <h5 class="">
                                    <span class="align-middle">Edit Student</span>
                                </h5>
                            </div>
                            <div class="flex-grow-1 wait_me_edit_form">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="edit_intake_name">First/Given Name <span class="text-danger">*</span></label>
                                            <input type="text" v-model="editItem.first_name" class="form-control" id="edit_intake_name" placeholder="Enter intake name">
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="edit_intake_name">Last/Family Name <span class="text-danger">*</span></label>
                                            <input type="text" v-model="editItem.last_name" class="form-control" id="edit_intake_name" placeholder="Enter intake name">
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="edit_end_date">Date of Birth</label>
                                                <flat-pickr
                                                    id="edit_end_date"
                                                    v-model="editItem.dob"
                                                    :config="configs.flatpickr"
                                                    class="form-control"
                                                    placeholder="Select end date"/>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="course_level_id">Course Level</label>
                                            <treeselect v-model="editItem.course_level_id" :options="courseLevelList" placeholder="Select Course level" id="course_level_id" autocomplete="off"/>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="course_title">Course Title</label>
                                            <input type="text" v-model="editItem.subject" class="form-control" id="course_title" placeholder="Enter course title">
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="country_id">Country of Domicile</label>
                                            <treeselect v-model="editItem.country_id" :options="countryList" placeholder="Select Country" id="country_id" autocomplete="off"/>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <button type="button" class="btn btn-success mr-1" @click.prevent="updateFormData()">Update</button>
                                        <!-- <button type="button" @click="update_reset()" class="btn btn-outline-secondary"><x-icon size="1.5x" class="custom-class"></x-icon> Close</button> -->
                                    </div>
                                </div>
                            </div>
                        </sidebar-form>
                    </div>
                </div>     

                <div class="table-responsive" v-show="inDeadline">
                    <table class="table table-hover" style="width:100%;">
                        <thead>
                            <tr>
                                <th class="text-center align-middle">
                                    <div>
                                        <input type="checkbox" name="checked_all" id="checked_all" v-model="form.add_to_intake.checked_all" v-on:change="check_all_students(true)"/>
                                        <!-- <label for="checked_all">All</label> -->
                                    </div>
                                </th>
                                <th class="align-middle" style="width:7%;"><span class="ml-1">Student ID</span></th>
                                <th class="align-middle" style="width:16%;">Name</th>
                                <th class="align-middle" style="width:8%;">DOB</th>
                                <th class="align-middle" style="width:16%;">Course Level</th>
                                <th class="align-middle" style="width:7%;">Start Date</th>
                                <th class="align-middle" style="width:16%;">Course Title</th>
                                <th class="align-middle" style="width:7%;">Tuition fee</th>
                                <!-- <th>Commission</th> -->
                                <th class="text-center align-middle"  style="width:7%;">Status</th>
                                <th class="text-center align-middle" style="width:10%;">Actions</th>
                            </tr>
                        </thead>
                        <tbody v-if="list.unassigned_students.length > 0">
                            <tr v-for="(item, index) in list.unassigned_students" :key="index">
                                <!-- <td>
                                    <span v-html="index + pagination.slOffset"></span>
                                </td> -->
                                <td class="text-center align-middle">
                                    <input type="checkbox" name="assigned_st_ids" id="assigned_st_ids" :value="item.id" v-model="form.add_to_intake.student_ids" v-on:change="check_all_students()"/>
                                </td>
                                <td>
                                    <small  class="ml-1" v-html="item.uni_ref_no"></small>
                                </td>
                                <td class="sorting_1">
                                    <div class="d-flex justify-content-left align-items-center">
                                        <!-- <div class="custom-control custom-checkbox">
                                            <input type="checkbox" class="form-check-input" value="">
                                        </div> -->
                                        <div class="avatar-wrapper">
                                            <div class="avatar  mr-1" v-tooltip="item.country.name">
                                                <img :src="item.flag_url" alt="Avatar" height="32" width="32">
                                            </div>
                                        </div>
                                        <div class="d-flex flex-column">
                                            <a href="#/student-list" class="user_name text-truncate text-dark" data-toggle="modal" data-target="#modals-show-student">
                                                <small class="font-weight-bold"><small class="text-primary" v-html="item.first_name+' '+item.last_name"></small> 
                                                    <small title="Possible conflict with other agents">
                                                        <i data-feather="alert-triangle" style="color:#FF9F43;"></i>
                                                    </small>
                                                </small>
                                            </a>
                                            <!-- <small class="emp_post text-muted" v-html="item.country.name"></small> -->
                                            <!-- <small v-if="item.dob" v-html="dDate(item.dob)"></small> -->
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <span v-if="item.dob" v-html="dDate(item.dob)"></span>
                                </td>
                                <td>
                                    <!-- <small  class="text-truncate align-middle" v-html="item.subject"></small><br> -->
                                            <small v-html="cn(item, 'course_level_data.name', 'N/A')" class="mr-1"></small>
                                    <small class="emp_post text-muted">

                                            <!-- <small v-html="dMonth(item.start_date)"></small> -->
                                    </small>
                                </td>
                                <td>
                                    <span v-html="dMonth(item.intake_month)"></span>
                                </td>
                                <td>
                                    <span v-html="item.subject"></span>
                                </td>
                                <td>
                                    <small class="d-block" v-html="'£ '+item.tuition_fee"></small>
                                </td>
                                <!-- <td>
                                    <span class="d-block">£ 00</span>
                                </td>
                                <td class="text-center">£ 00</td> -->
                                <!-- <td>
                                    <small class="text-truncate align-middle float-right">£ 3,200</small><br>
                                    <small class="emp_post text-muted float-right">@ 20%</small>
                                </td> -->
                                <td class="text-center">
                                    <small :class="'badge badge-pill badge-light-' + item.submission_status.color" v-html="item.submission_status.name"></small>
                                    <!-- <small class="d-block text-muted" v-html="dMonth(item.intake_month)"></small> -->
                                    <small v-if="cn(item, 'intake.intake_month', null)" class="d-block text-muted" v-html="dMonth(item.intake.intake_month)"></small>
                                </td>
                                <td>

                                    <div class="text-center" v-if="item.submission_status.code != 'as_submission_submitted'">
                                        <button type="button" class="btn btn-outline-primary btn-sm" @click="editData(item)" v-if="item.submission_status.color !== 'success'" style="margin-right: 5px"><edit-icon size="1.5x" class="custom-class"></edit-icon></button>

                                        <!-- <button type="button" class="btn btn-outline-success btn-sm" @click="addToIntake(item)" v-if="item.added_to_intake_at == null"><plus-square-icon size="1.5x" class="custom-class"></plus-square-icon></button> -->
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <th colspan="9" class="text-center">
                                    <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>
                                    Found no student data.
                                </th>
                            </tr>
                        </tbody>
                    </table>
                    
                    <!-- <b-pagination
                        class="mt-1 mr-1"
                        align="right"
                        v-model="pagination.currentPage"
                        pills
                        :per-page="pagination.perPage"
                        :total-rows="pagination.totalRows"
                        @input="searchData"
                        >
                    </b-pagination> -->

                </div>
            </div>
        </div>
    </main-layout>
</section>
</template>

<script>

import MainLayout from './MainLayout.vue';
import feather from 'feather-icons';
//import { BPagination, BDropdown, BDropdownItem } from 'bootstrap-vue';
import Treeselect from '@riophae/vue-treeselect';
import SidebarForm from '../SidebarForm';
import flatPickr from 'vue-flatpickr-component';
import Swal from 'sweetalert2';
//import { PlusSquareIcon, EditIcon, UsersIcon } from 'vue-feather-icons';
import { EditIcon, UsersIcon } from 'vue-feather-icons';
import Hashids from 'hashids';
import moment from 'moment';

export default{
    name: 'AllStudentList',
    components:{
        MainLayout,
        //BPagination,
        //BDropdown,
        //BDropdownItem,
        Treeselect,
        SidebarForm,
        flatPickr,
        // MoreVerticalIcon,
        //PlusSquareIcon,
        EditIcon,
        UsersIcon 
    },
    data:()=>{
        return {
            list:{
                unassigned_students:[],
                agent_statuses:[{
                    id: 'inactive',
                    label: 'Inactive',
                    btn_color: 'outline-danger',
                    text_color: 'text-danger'
                }, {
                    id: 'pending',
                    label: 'Pending',
                    btn_color: 'outline-warning',
                    text_color: 'text-warning'
                },{
                    id: 'approved',
                    label: 'Approved',
                    btn_color: 'outline-success',
                    text_color: 'text-success'
                }]
            },
            studentList: [],
            regionList: [],
            regionCountryList: [],
            statusTreeSlectList: [],
            statusList: [],
            inDeadline: true,
            intakeItem: {},
            flag:{
                show_agent_form:false,
                show_filter:false,
                show_modal_form:false
            },
            form:{
                agent_status_update:{
                    agent_id:'',
                    new_status: ''
                },
                add_to_intake:{
                    intake_id:0,
                    student_ids:[],
                    checked_all: false
                }
            },
            search: {
                course_level_id: null,
                country_id: null,
                uni_ref_no: '',
                ucas_id: '',
                name: '',
                last_name: '',
                start_date: '',
                submission_status_id: null,
                intake_id: null,
            },
            editItem: {},
            configs: {
                flatpickr: {
                    dateFormat: 'd M Y',
                    maxDate: new Date()
                },        
                flatpickr2: {
                    dateFormat: 'd M Y',
                    mode: "range"
                }        
            },
            courseLevelList: [],
            courseTitleList: [],
            info: {
                intake:{
                    id: null
                },
                download_url:'#',
            },
        };
    },
    watch: {},
    created: async function(){

        this.setup_urls();
        this.init_data();

        const countries = await this.countries();

        this.countryList = countries.map(item => {
            return {
                id: item.id,
                label: item.name
            }
        });

        this.gen_download_url();

    },
    mounted: function(){
        // this.checkIntakeInDeadline();
        
        this.get_unassigned_students();
        this.getRegionList()
        this.getRegionCountryList()
        this.getCourseLevelList()
        this.getStatusList()
        feather.replace();
    },
    methods:{
        init_data: async function(){

            var ref=this;
            var jq=ref.jq();
            var url=ref.url("api/v1/agent/ajax/unassigned_students_init_data");

            var qstr={
                intake_id: ref.hash_id(ref.$route.params.intake_hashed_id, false)[0]
            };

            if(!qstr.intake_id){
                ref.alert('Invalid intake argument', 'error');
                ref.$router.push({name: 'SubmitToUniversity'});
                return;
            }
            
            try{
                var res=await jq.get(url, qstr);
                ref.info.intake=res.data.intake;
            }catch(err){
                ref.alert(ref.err_msg(err), 'error');
            }

        },
        check_all_students: function(checked_all=false){

            var ref=this;
            var form=ref.form.add_to_intake;

            if(checked_all){
                if(form.checked_all){
                    ref.list.unassigned_students.forEach(row=>{
                        form.student_ids.push(row.id);
                    })
                }else{
                    form.student_ids=[];
                }
            }else if(ref.list.unassigned_students.length > 0 && ref.list.unassigned_students.length==form.student_ids.length){

                form.checked_all=true;

            }else form.checked_all=false;

        },
        ClearSearchData () {
            this.search.course_level_id = null
            this.search.country_id = null
            this.search.uni_ref_no = ''
            this.search.ucas_id = ''
            this.search.name = ''
            this.search.last_name = ''
            this.search.start_date = ''
            this.search.submission_status_id = null
            this.search.intake_id = null
        },
        async searchData () {
            this.get_unassigned_students();
            this.gen_download_url();
        },
        toggle_agent_form: function(){
            this.flag.show_agent_form=!this.flag.show_agent_form;
        },
        addToIntakeAllStudent: function(){

            var ref=this;

            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger ml-2'
                },
                buttonsStyling: false
            })

            swalWithBootstrapButtons.fire({
                title: 'Add To Intake: '+ref.info.intake.name,
                html: `Add selected students with ${ref.info.intake.name} intake?`,
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                reverseButtons: true
            }).then((result) => {
                if (result.isConfirmed) {
                    this.addToIntakeAllStudentConfirm()
                }
            })
        },
        addToIntakeAllStudentConfirm: async function(){

            var ref=this;
            var jq=ref.jq();
            var url=ref.url('api/v1/agent/ajax/add_to_intake_agent_all_students');
            var form_data=ref.form.add_to_intake;

            form_data.intake_id=ref.info.intake.id;

            try {
                ref.wait_me('.agency-list-card');
                var res = await jq.post(url, form_data);
                ref.get_unassigned_students();
                ref.reset_add_to_intake();
                Swal.fire({
                    position: 'top-center',
                    icon: 'success',
                    title: res.msg,
                    showConfirmButton: false,
                    timer: 1500
                })

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
            finally {
                ref.wait_me('.agency-list-card', 'hide');
            }
        },
        checkIntakeInDeadline: async function(){

            var ref=this;
            var jq=ref.jq();

            try {
                ref.wait_me('.agency-list-card');
                const params = Object.assign({}, this.search)
                var res = await jq.get(ref.url('api/v1/agent/ajax/check_in_intake_deadline'), params);
                ref.intakeItem = res.data.intake
                if (res.data.in_expiry_intake) {
                    this.inDeadline = true
                } else {
                    this.inDeadline = false
                }

            } catch (err) {
                // ref.alert(ref.err_msg(err), 'error');
            }
            finally {
                ref.wait_me('.agency-list-card', 'hide');
            }
        },
        //getListData: async function(){
        get_unassigned_students: async function(){

            var ref=this;
            var jq=ref.jq();
            var url=ref.url('api/v1/agent/ajax/get_unassigned_students');

            try {
                ref.wait_me('.agency-list-card');
                const params = Object.assign({}, { page: this.pagination.currentPage, per_page: this.pagination.perPage },  this.search)
                var res = await jq.get(url, params);
                this.list.unassigned_students=res.data.unassigned_students.map(item => {
                    const StatusData = Object.assign({}, item.submission_status, JSON.parse(item.submission_status.data))
                    return Object.assign({}, item, { submission_status: StatusData })
                });

                //this.paginationData(res.data.unassigned_students)

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
            finally {
                ref.wait_me('.agency-list-card', 'hide');
            }
        },
        getRegionList: async function () {
            var ref=this;
            var jq=ref.jq();
            var res = await jq.get(ref.url('api/v1/university/ajax/get_region_dropdown_list'));
            this.regionList = res.data.regionList.map(item => {
                return {
                    id: item.id,
                    label: item.title
                }
            })
        },
        getRegionCountryList: async function () {
            var ref=this;
            var jq=ref.jq();
            var res = await jq.get(ref.url('api/v1/university/ajax/get_region_countries_list'));
            this.regionCountryList = res.data.regionCountryList
        },
        agent_status: function(row){

            var ref=this;
            return ref.list.agent_statuses.find(each=>{
                return each.id==row.status;
            });

        },
        update_agent_status: async function(){

            this.$refs.agent_status_confirmation.hide();

            var ref=this;
            var jq=this.jq();
            var url=ref.url('api/v1/university/ajax/update_agent_status');

            try{

                ref.wait_me('.agency-list-card');
                var res=await jq.post(url, ref.form.agent_status_update);

                ref.form.agent_status_update.agent_id='';
                ref.form.agent_status_update.new_status='';
                ref.alert(res.msg);
                ref.get_unassigned_students();

            }catch(err){
                ref.alert(ref.err_msg(err), 'error')
            }finally{
                ref.wait_me('.agency-list-card', 'hide');
            }

        },
        send_single_mail: function(agent_id){

            var ref=this;
            ref.form.send_invitation.checked_all=false;
            ref.form.send_invitation.agent_ids.push(agent_id);
            ref.invitation_confirmation();

        },
        getCountryList: function (regionId) {
            if (regionId) {
                const regionCountryList = this.regionCountryList.filter(item => item.region_id == regionId)
                const countryMappingData = regionCountryList.map(item => {
                    const countryObj = this.$store.state.site.countries.find(country => country.id == item.country_id)
                    const countryData = {
                        id: countryObj.id,
                        label: countryObj.name,
                    }
                    return Object.assign({}, countryData)
                })
                return countryMappingData
            }
        },
        toggle_filter : function(){
            this.flag.show_filter=!this.flag.show_filter;
        },
        getCourseLevelList: async function(){

            var ref=this;
            var jq=ref.jq();
            try {
                var res = await jq.get(ref.url('api/v1/agent/ajax/get_uni_course_level_list'));
                this.courseLevelList = res.data.list.map(item => {
                    return {
                        id: item.id,
                        label: item.name
                    }
                });
                this.courseTitleList = res.data.list.map(item => {
                    return {
                        id: item.id,
                        label: item.name
                    }
                });
                // console.log('this.courseLevelList', res.data.list)

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
        },
        toggle_modal_form: function(){
            this.flag.show_modal_form = !this.flag.show_modal_form;
        },
        editData: function (item) {
            this.editItem = JSON.parse(JSON.stringify(item))
            this.editItem.dob = moment(this.editItem.dob).format("DD MMM YYYY");
            this.flag.show_modal_form = !this.flag.show_modal_form;
            // console.log('edit data', item)
        },
        addToIntake: function (item) {
            var ref=this;
            this.editItem = item
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-secondary'
                },
                buttonsStyling: false
            })

            swalWithBootstrapButtons.fire({
                title: 'Add To Intake',
                html: "Are you sure do you want to add to intake?",
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: 'Submit',
                cancelButtonText: 'Cancel',
                reverseButtons: true,
                input:'select',
                inputPlaceholder: 'Select an Intake',
                inputOptions:ref.list.intakes,
                inputValidator: (value) => {
                    return new Promise((resolve) => {
                        if(value) resolve();
                        else{
                            resolve('Please! select an intake form dropdown.');
                        }
                    });
                }
            }).then((result) => {
                if(result.isConfirmed && result.value){
                    this.addToIntakeConfirm(item, result.value);
                }
            })
        },
        addToIntakeConfirm: async function(item, intake_id=null){

            var ref=this;
            var jq=ref.jq();

            var form_data=ref.clone(item);
            form_data.intake_id=intake_id;

            try {
                var res = await jq.post(ref.url('api/v1/agent/ajax/add_to_intake_agent_student'), form_data);
                ref.get_unassigned_students();
                ref.reset_add_to_intake();
                Swal.fire({
                    position: 'top-center',
                    icon: 'success',
                    title: res.msg,
                    showConfirmButton: false,
                    timer: 1500
                })

            } catch (err) {
                // ref.alert(ref.err_msg(err), 'error');
            }
        },
        updateFormData: async function () {

            var ref=this;
            var jq=ref.jq();
            try{
                ref.wait_me('.wait_me_edit_form');
                var res = await jq.post(ref.url('api/v1/agent/ajax/update_agent_student_info'), ref.editItem);
                this.get_unassigned_students()
                this.toggle_modal_form()
                // ref.alert(res.msg);
                Swal.fire({
                    position: 'top-center',
                    icon: 'success',
                    title: res.msg,
                    showConfirmButton: false,
                    timer: 1500
                })
            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
            finally {
                ref.wait_me('.wait_me_edit_form', 'hide');
            }
        },
        getStatusList: async function () {
            var ref=this;
            var jq=ref.jq();
            try {
                const params = { group_code: 'agent_student_submission_statuses' }
                var res = await jq.get(ref.url('api/v1/agent/ajax/get_statuses_by_groups'), params);
                this.statusTreeSlectList = res.data.statuses.map(item => {
                    return {
                        id: item.id,
                        label: item.name
                    }
                })
                // console.log('res.data.statuses', this.statusTreeSlectList)
                this.statusList = res.data.statuses.map(item => {
                    return Object.assign({}, item, JSON.parse(item.data))
                })
            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
        },
        gen_download_url: function () {
            var ref=this;
            var qstr = new URLSearchParams(this.search).toString()
            var hash_ids  = new Hashids('sams_pay_export_hash_salt', 30);
            ref.info.download_url = ref.url('download/agent_students_export_excel/' + hash_ids.encode(ref.store_auth_user.institute_id)+ '?'+ qstr);

        },
        reset_add_to_intake: function(){
            var form=this.form.add_to_intake;
            form.student_ids=[],
            form.checked_all=false;
            form.intake_id=0;
        }
    },
    computed: {
        store_countries: function(){
            
            return this.$store.state.site.countries

        },
        treeselect_countries: function(){
            return this.$store.state.site.countries.map(each=>{
                return {
                    id: each.id,
                    label: each.name
                }
            });
        },
        store_auth_user:function(){
            return this.cn(this.$store.state, 'site.auth_user', null);
        }
    }
}
</script>
<style type="text/css">
    .badge.badge-light-blue {
        background-color: rgba(51, 51, 255, 0.12);
        color: #3333ff !important;
    }        
    .badge.badge-light-declined {
        background-color: rgba(255, 51, 0, 0.12);
        color: #ff3300 !important;
    }
    .table th, .table td {
         padding: 0.72rem 0.5rem !important; 
        vertical-align: middle;
    }
    .btn-sm, .btn-group-sm > .btn {
        padding: 0.486rem .7rem !important; 
    }
</style>
