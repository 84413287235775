<template>
  <section class="add-student">
    <main-layout>
      <div class="content-header row content_header_breadcrumb">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h5 class="content-header-title float-left mb-0" style="margin-top: 5px">Claim List</h5>
              <div class="breadcrumb-wrapper">
                <!-- <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item">
                    <router-link to="/student-list">Manage Students</router-link>
                  </li>
                  <li class="breadcrumb-item active">Add Student</li>
                </ol> -->
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><router-link to="/">Home</router-link></li>
                    <li class="breadcrumb-item"><router-link to="/submit-to-university">Intake List</router-link></li>
                    <li class="breadcrumb-item"><router-link :to="'/student-list/'+$route.params.intake_id">{{ cn(intakeItem, 'name', 'Loading...') }}</router-link></li>
                    <li class="breadcrumb-item"><router-link :to="'/student-list/'+$route.params.intake_id">Student List</router-link></li>
                    <li class="breadcrumb-item active">Add Student</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div
          class="
            content-header-right
            text-md-right
            col-md-3 col-12
            d-md-block d-none
          "
        >
          <!-- <div class="form-group breadcrumb-right">
            <router-link
              class="dt-button btn btn-primary btn-add-record ml-2"
              to="/add-new-multiple-student"
              ><span>Import Excel</span>
            </router-link>
          </div> -->
          <div class="form-group breadcrumb-right">
            <router-link
              v-if="$route.query.list_id && ($route.query.list_id != undefined || $route.query.list_id != 'undefined')"
              class="dt-button btn btn-primary btn-add-record ml-2 cust_btn"
              :to="`/add-multiple-student/${$route.params.intake_id}?list_id=${$route.query.list_id}`"
              ><span>Import Excel</span>
            </router-link>
            <router-link
              v-else
              class="dt-button btn btn-primary btn-add-record ml-2 cust_btn"
              :to="`/add-multiple-student/${$route.params.intake_id}`"
              ><span>Import Excel</span>
            </router-link>
          </div>
        </div>
      </div>

      <div class="row add-student-form-section">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Add Multiple Student</h4>
            </div>
            <button
              type="button"
              class="btn btn-primary save_button_top cust_btn"
              @click.prevent="insertFormData()"
            >
              Save
            </button>

            <div class="card-body">
              <div class="table-responsive" style="min-height: 250px">
                <table class="table table-hover">
                  <thead class="bg-primary">
                    <tr>
                      <th width="120px">Student ID<span class="text-danger"></span></th>
                      <th width="120px">UCAS ID<span class="text-danger"></span></th>
                      <th>First Name<span class="text-danger">*</span></th>
                      <th>Last Name<span class="text-danger">*</span></th>
                      <!-- <th>DOB</th> -->
                      <th>Country of Domicile</th>
                      <!-- <th>Intake<span class="text-danger">*</span></th> -->
                      <!-- <th>Course Level</th> -->
                      <!-- <th width="250px">Subject Name</th> -->
                      <!-- <th>Tuition fee</th> -->
                      <!-- <th>Commission Type</th> -->
                      <!-- <th>Funding</th> -->
                      <th width="30px"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row, index) in form.students" :key="index">
                      <td>
                        <input
                          type="text"
                          id="uni_ref_no"
                          class="form-control form-control-sm"
                          v-model="row.uni_ref_no"
                          placeholder="Srudent ID"
                        />
                      </td>
                       <td>
                        <input
                          type="text"
                          id="ucas_id"
                          class="form-control form-control-sm"
                          v-model="row.ucas_id"
                          placeholder="UCAS ID"
                        />
                      </td>
                      <td width="130" class="First Name">
                        <input
                          type="text"
                          id="first_name"
                          class="form-control form-control-sm"
                          v-model="row.first_name"
                          placeholder="First Name"
                        />
                      </td>
                      <td width="130" class="Last Name">
                        <input
                          type="text"
                          id="last_name"
                          class="form-control form-control-sm"
                          v-model="row.last_name"                          
                          placeholder="Last Name"
                        />
                      </td>

                      <!-- <td width="120" class="dob">
                        <flat-pickr
                          id="dob"
                          v-model="row.dob"
                          :config="configs.flatpickr"
                          class="form-control form-control-sm"
                        />
                      </td> -->

                      <td width="175" class="Nationality">
                        <v-select
                          v-model="row.nationality_id"
                          :options="treeselect_countries"
                          :reduce="(item) => item.id"                          
                          placeholder="Select Country of Domicile"
                        ></v-select>
                      </td>

                      <!-- <td width="200">
                        <div class="form-row">
                          <div class="col">
                            <v-select
                              v-model="row.intake_month"
                              :options="monthList"
                              :reduce="(item) => item.id"
                              placeholder="Month"
                              id="intake_month"
                              autocomplete="off"
                            />
                          </div>
                          <div class="col">
                            <v-select
                              v-model="row.intake_year"
                              :options="yearList"
                              :reduce="(item) => item.id"
                              placeholder="Year"
                              id="intake_year"
                              autocomplete="off"
                            />
                          </div>
                        </div>
                      </td> -->

                      <!-- <td width="130">
                        <v-select
                          v-model="row.course_level_id"
                          :options="courseLevelList"
                          :reduce="(item) => item.id"
                        ></v-select>
                      </td> -->

                      <!-- <td>
                        <input
                          type="text"
                          id="subject_name"
                          class="form-control form-control-sm"
                          v-model="row.subject_name"
                        />
                      </td> -->

                      <!-- <td>
                        <input
                          type="text"
                          id="tuition_fee"
                          class="form-control form-control-sm"
                          v-model="row.tuition_fee"
                        />
                      </td> -->

                      <!-- <td width="180">
                        <v-select
                          v-model="row.commission_type"
                          :options="commissionTypeList"
                          :reduce="(item) => item.id"
                        ></v-select>
                      </td> -->

                      <!-- <td width="180">
                        <v-select
                          v-model="row.funding_source"
                          :options="list.funding_sources"
                          :reduce="(item) => item.id"
                        ></v-select>
                      </td> -->

                      <td class="text-center">
                        <button
                          v-if="index == 0"
                          v-on:click="add_student_form_row(index)"
                          type="button"
                          class="btn btn-primary btn-sm btn-block cust_btn"
                        >
                          <plus-icon
                            size="1.5x"
                            class="custom-class"
                          ></plus-icon>
                        </button>
                        <button
                          v-else
                          v-on:click="remove_student_form_row(index)"
                          type="button"
                          class="btn btn-danger btn-sm btn-block" style="border-radius: 0.357rem !important;"
                        >
                          <x-icon size="1.5x" class="custom-class"></x-icon>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot v-if="validationError">
                    <tr>
                      <td colspan="5">
                        <div class="alert alert-danger p-50">{{ validationError }}</div>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main-layout>
  </section>
</template>

<script>
import MainLayout from "./MainLayout.vue";
import feather from "feather-icons";
// import Treeselect from '@riophae/vue-treeselect';
import swal from "bootstrap-sweetalert";
// import flatPickr from "vue-flatpickr-component";
import { PlusIcon, XIcon } from "vue-feather-icons";
import { BAlert } from 'bootstrap-vue';

export default {
  name: "AddStudent",
  components: {
    MainLayout,
    // Treeselect,
    // flatPickr,
    PlusIcon,
    XIcon,
    BAlert
  },
  data: () => {
    return {
      visible: true,
      validationError: '',
      list: {
        countries: [],
        course_levels: [],
        funding_sources: [
          { id: "Self Funded", label: "Self Funded" },
          { id: "Sponsored", label: "Sponsored" },
        ],
      },
      saveData: true,
      configs: {
        flatpickr: {
          dateFormat: "d M Y",
          maxDate: "today",
        },
      },
      intake_id: "",
      form: {
        students: [
          {
            uni_ref_no: "",
            ucas_id: "",
            first_name: "",
            last_name: "",
            email: "",
            dob: "",
            mobile: "",
            gender: "",
            passport_no: "",
            nationality_id: null,
            intake_id: null,
            intake_month: null,
            intake_year: null,
            course_level_id: null,
            subject_name: "",
            tuition_fee: "",
            progression: "",
            commission_type: 4,
            funding_source: null,
          },
        ],
      },
      row: {
        uni_ref_no: "",
        ucas_id: "",
        first_name: "",
        last_name: "",
        email: "",
        dob: "",
        mobile: "",
        gender: "",
        passport_no: "",
        nationality_id: null,
        intake_id: null,
        intake_month: "",
        course_level_id: null,
        subject_name: "",
        tuition_fee: "",
        progression: "",
        commission_type: null,
      },
      intake_lists: [],
      commissionTypeList: [],
      courseLevelList: [],
      monthList: [],
      yearList: [],
      intakeItem: {}
    };
  },
  created() {
    this.setup_urls();
    this.intake_id = this.hash_id(this.$route.params.intake_id, false)[0]
    this.getIntakeById()
    this.init_data();
    this.getMonghYearData();
    this.getStatusListByGroupCode();
  },
  methods: {
    init_data: async function () {
      var ref = this;
      var jq = this.jq();
      var url = ref.url("api/v1/agent/ajax/auth_user_info");

      try {
        var res = await jq.get(url);
        ref.list.countries = res.data.countries;
        ref.list.course_levels = res.data.course_level;
      } catch (err) {
        res.msg(res.err_msg(err));
      }
    },
    getMonghYearData: async function () {
      var ref = this;
      var jq = ref.jq();
      var url = ref.url("api/v1/agent/ajax/mulitiple_student_inport_init_data");
      try {
        var res = await jq.get(url);
        this.monthList = res.data.months;
        this.yearList = res.data.years;
      } catch (err) {
        ref.alert(ref.err_msg(err), "error");
      }
    },
    getStatusListByGroupCode: async function () {
      var ref = this;
      var jq = this.jq();
      const params = { group_code: "agent_commission_type_statuses" };
      var url = ref.url("api/v1/agent/ajax/get_statuses_list_by_group_code");

      try {
        var res = await jq.get(url, params);
        this.commissionTypeList = res.data.list.map((item) => {
          return {
            id: item.id,
            label: item.name,
          };
        });
      } catch (err) {
        res.msg(res.err_msg(err));
      }
    },
    student_form_row: () => {
      return {
        uni_ref_no: "",
        ucas_id: "",
        first_name: "",
        last_name: "",
        email: "",
        dob: "",
        mobile: "",
        gender: "",
        passport_no: "",
        nationality_id: null,
        intake_id: null,
        course_level_id: null,
        subject_name: "",
        tuition_fee: "",
        progression: "",
        commission_type: null,
      };
    },
    add_student_form_row: function () {
      var ref = this;
      ref.form.students.push(ref.student_form_row());
      feather.replace();
    },
    remove_student_form_row: function (index) {
      swal(
        {
          title: "Are you sure?",
          text: "Press Yes to confirm or Cancel",
          type: "warning",
          showCancelButton: true,
          cancelButtonClass: "btn-light",
          confirmButtonClass: "btn-danger",
          confirmButtonText: "Yes",
          cancelButtonText: "Cancel",
        },
        (isConfirm) => {
          if (!isConfirm) return;
          this.form.students.splice(index, 1);
        }
      );
    },
    insertFormData: async function () {
      var ref = this;
      // console.log(ref.url('api/v1/agent/ajax/add_student'));
      var jq = ref.jq();

      try {
        ref.wait_me(".add-student-form-section");

        var formData = {
          students: ref.form.students,
          intake_id: this.intake_id
        };

        var res = await jq.post(ref.url("api/v1/agent/ajax/add_student"), formData);
        // ref.alert(res.msg, "success", () => {
          
        // });
        ref.alert(res.msg);
        if (this.$route.query.list_id) {
          this.$router.push({ name: 'IntakeWiseStudentList', params: { list_id:this.$route.query.list_id, intake_id: this.$route.params.intake_id } })
        } else {
          this.$router.push({ name: 'StudentListByIntake', params: { id: this.$route.params.intake_id } })
        }

        this.student_form_row();
      } catch (err) {
        this.validationError = ref.err_msg(err)
        // ref.alert(ref.err_msg(err), "error");
      } finally {
        ref.wait_me(".add-student-form-section", "hide");
      }
    },
    getIntakeList: async function () {
      var ref = this;
      var jq = ref.jq();
      try {
        var res = await jq.get(
          ref.url("api/v1/agent/ajax/get_company_university_intake_list")
        );
        this.intake_lists = res.data.list.map((item) => {
          return {
            id: item.id,
            label: item.name,
          };
        });
      } catch (err) {
        ref.alert(ref.err_msg(err), "error");
      }
    },
    getCourseLevelList: async function () {
      var ref = this;
      var jq = ref.jq();
      try {
        var res = await jq.get(
          ref.url("api/v1/agent/ajax/get_uni_course_level_list")
        );
        this.courseLevelList = res.data.list.map((item) => {
          return {
            id: item.id,
            label: item.name,
          };
        });
      } catch (err) {
        ref.alert(ref.err_msg(err), "error");
      }
    },
    getIntakeById: async function(){
        var ref=this;
        var jq=ref.jq();

        try {
            const params = Object.assign({intake_id: this.intake_id})
            var res = await jq.get(ref.url('api/v1/agent/ajax/get_intake_by_id'), params);
            ref.intakeItem = res.data.intake

        } catch (err) {
            // ref.alert(ref.err_msg(err), 'error');
        }
    },
  },
  computed: {
    /* store_countries: function(){
            return this.$store.state.site.countries
        }, */
    treeselect_countries: function () {
      return this.list.countries.map((each) => {
        return {
          id: each.id,
          label: each.name,
        };
      });
    } /* ,
        store_course_levels: function(){
            return this.$store.state.site.course_levels
        }, */,
    treeselect_course_levels: function () {
      return this.list.course_levels.map((each) => {
        return {
          id: each.id,
          label: each.name,
        };
      });
    },
  },
  mounted: function () {
    feather.replace();
    this.getIntakeList();
    this.getCourseLevelList();
  },
};
</script>

<style>
/* .gridtable tbody{
      display: block;
      height: 300px;
      overflow-y: scroll;
    } */

.gridtable thead,
.gridtable tbody tr {
  display: table;
  table-layout: fixed;
  width: 100%;
}
.gridtable thead {
  width: calc(100% - 1.1em);
}
.gridtable thead {
  position: relative;
}
.gridtable thead th:last-child:after {
  content: " ";
  position: absolute;
  background-color: #337ab7;
  width: 1.3em;
  height: 38px;
  right: -1.3em;
  top: 0;
  border-bottom: 2px solid #ddd;
}

.gridtable .table td {
  padding: 0rem 0rem;
  vertical-align: middle;
}
.gridtable .form-control-sm {
  border-radius: unset;
}
.gridtable.table-bordered td {
  border: 0px solid #ebe9f1;
}
.icon {
  padding: 5px;
  margin-top: 22px;
}
.bg-primary {
  text-align: center;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: unset;
  opacity: 1;
}
.select2-container--classic .select2-selection--single,
.select2-container--default .select2-selection--single {
  min-height: 2rem;
  padding: 0px;
  border: 1px solid #d8d6de;
}

.select2-container--default .select2-selection--single {
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 0px;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  color: #444;
  line-height: 26px;
}
.select2-container--classic
  .select2-selection--single
  .select2-selection__arrow
  b,
.select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  background-image: url(
    data:image/svg + xml,
    %3Csvgxmlns="http://www.w3.org/2000/svg"viewBox="0 0 24 24"fill="none"stroke="%23d8d6de"stroke-width="2"stroke-linecap="round"stroke-linejoin="round"class="feather feather-chevron-down"%3E%3Cpolylinepoints="6 9 12 15 18 9"%3E%3C/polyline%3E%3C/svg%3E
  );
  background-size: 18px 14px, 18px 14px;
  background-repeat: no-repeat;
  height: 1rem;
  padding-right: 1.5rem;
  margin-left: 0;
  margin-top: -6px;
  left: -2px;
  border-style: none;
}
.select2-container--classic .select2-search--dropdown .select2-search__field,
.select2-container--default .select2-search--dropdown .select2-search__field {
  outline: none !important;
  border-radius: 0rem;
  border-color: #d8d6de;
  padding: unset;
}

.card {
  margin-bottom: 0rem;
}
.vue-treeselect--focused,
.vue-treeselect--open {
  z-index: 9999999 !important;
  max-height: 150px !important;
}
.content_header_breadcrumb {
  position: relative;
}
.save_button {
  /* position: absolute; */
  position: fixed;
  top: 235px;
  right: 50px;
  z-index: 9999;
}
.save_button_top {
  position: absolute;
  top: 20px;
  right: 10px;
}
</style>
