<template>
<section class="dashboard">
    <main-layout>
        <!-- <div class="row p-1 height-250">
            <div class="col-md-12 bg-primary">
                <ol class="dashboard_banner">
                    <li>Congratulations! You have completed your profile.</li>
                    <li>If you want to change your password, you can do that through <strong>Change Password</strong>.</li>
                    <li>As the next step, you can now upload your student data under <strong>Manage Students.</strong></li>
                    <li>Once the submission window opens by the University, you&rsquo;ll be able to submit your list to the University from the <strong>Submit List.</strong>&nbsp;</li>
                    <li>Once a list is submitted, you can&rsquo;t make further changes to the list. The University can assess your list as per their policy.</li>
                    <li>Once the University approves your list, you&rsquo;ll be notified within the system to see your <strong>Confirmed List</strong> and start claiming your commission through <strong>Send Invoice</strong>.</li>
                </ol>
            </div>
        </div> -->
        <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                <div class="dashboard_welcome">
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-6 col-lg-6">
                            <div class="welcome_left">
                                <div class="inner_box">
                                    <img :src="url('static/uni/welcome_msg_img.png')" width="55">
                                    <h3>Welcome!</h3>
                                    <h1>{{ cn(store_auth_user, 'name', 'User') }}</h1>
                                    <p>{{ dDate(new Date()) }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-6">
                            <div class="instructions">
                                <div class="item">
                                    <div class="left">
                                        <star-icon size="1.5x" class="custom-class mr-1"></star-icon>
                                    </div>
                                    <div class="text">
                                        If you want to change your password, you can do that through <router-link to="/change-password">Change Password</router-link>.
                                    </div>
                                </div>
                                <div class="item">
                                    <div class="left">
                                        <star-icon size="1.5x" class="custom-class mr-1"></star-icon>
                                    </div>
                                    <div class="text">
                                        Once the submission window opens by the University, the university will send you a notification email. Then you’ll be able to submit your list to the University from the <router-link to="/submit-to-university">Submit Your Claim</router-link> tab.
                                    </div>
                                </div>
                                <div class="item">
                                    <div class="left">
                                        <star-icon size="1.5x" class="custom-class mr-1"></star-icon>
                                    </div>
                                    <div class="text">
                                        You should submit your list before the submission deadline. Once a list is submitted, you can’t make further changes to the list. The University will contact you with updates regarding your claim list.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main-layout>
</section>
</template>

<script>

import MainLayout from './MainLayout.vue';
import feather from 'feather-icons';
import { StarIcon, AwardIcon } from 'vue-feather-icons'
import { BAlert } from 'bootstrap-vue'

export default{
    name: 'Dashbaord',
    metaInfo: {
        titleTemplate: 'University Of Sheffield | Dashbaord'
    },
    components:{
        MainLayout,
        BAlert,
        StarIcon, AwardIcon
    },
    data:()=>{
        return {
            // auth_user: {}
        };
    },
    created () {
        // this.auth_user = this.$store.state.site.auth_user
        this.setup_urls();
    },
    methods:{},
    computed: {
        store_auth_user:function(){
            return this.cn(this.$store.state, 'site.auth_user', null);
        }
    },
    mounted: function(){
        feather.replace();
    }
}
</script>

<style>
    .text-white {
        color: #FFF !important;
    }
    .dashboard_banner {
        color: #FFF;
        text-align: justify;
        padding: 10px;
    }
    .congratulations_card_height {
        /* height: 260px; */
        height: 235px;
    }

    .dashboard_welcome{
        background: #fff;
        padding: 21px;
        border-radius: 0.357rem;
        margin-top: 8px;
    }
    .dashboard_welcome .welcome_left .inner_box{
        width: fit-content;
        text-align: center;
        margin: 5% auto 0;
    }
    .dashboard_welcome .welcome_left .inner_box img{
        transform: rotate(160deg);
        margin-bottom: 15px;
    }
    .dashboard_welcome .welcome_left .inner_box h3{
        color: #472884;
        margin-bottom: 21px;
        font-size: 1.35rem;
    }
    .dashboard_welcome .welcome_left .inner_box h1{
        color: deepskyblue;
        font-size: 1.55rem;
        margin-bottom: 21px;
        font-weight: 600;
    }
    .dashboard_welcome .instructions{
        background: #F0F0FF;
        padding: 21px;
        border-radius: 0.357rem;
        min-height: 17rem;
        align-content: space-evenly;
    }
    .dashboard_welcome .instructions .item{
        display: flex;
        margin-bottom: 21px;
        color: #472884;
    }
    .dashboard_welcome .instructions .item:last-child{
        margin-bottom: 0;
    }
    .dashboard_welcome .instructions .item .text a{
        color: deepskyblue;
    }

</style>
