<template>
<section class="main-header">

    <!-- BEGIN: Header-->
    <nav id="header_backgroud" class="header-navbar navbar-expand-lg navbar navbar-fixed align-items-center navbar-shadow navbar-brand-center" data-nav="brand-center">
        <div class="navbar-header d-xl-block d-none">
            <ul class="nav navbar-nav">
                <li class="nav-item">
                    <a class="navbar-brand" href="#">
                        <!-- <img :src="url('static/vuexy/images/logo.png')" width="100"> -->
                    </a>
                </li>
            </ul>
        </div>
        <div class="navbar-container d-flex content">
            <div class="bookmark-wrapper d-flex align-items-center">
                <!-- <ul class="nav navbar-nav d-xl-none">
                    <li class="nav-item"><a class="nav-link menu-toggle" href="javascript:void(0);"><i class="ficon" data-feather="menu"></i></a></li>
                </ul> -->
                <!-- <ul class="nav navbar-nav bookmark-icons">
                    <li class="nav-item d-none d-lg-block"><a class="nav-link" href="app-chat.html" data-toggle="tooltip" data-placement="top" title="Chat"><i class="ficon" data-feather="message-square"></i></a></li>
                    <li class="nav-item d-none d-lg-block"><a class="nav-link" href="app-calendar.html" data-toggle="tooltip" data-placement="top" title="Calendar"><i class="ficon" data-feather="calendar"></i></a></li>
                    <li class="nav-item d-none d-lg-block"><a class="nav-link" href="app-todo.html" data-toggle="tooltip" data-placement="top" title="Todo"><i class="ficon" data-feather="check-square"></i></a></li>
                </ul> -->
                <ul class="nav navbar-nav">
                    <!-- <li class="nav-item d-none d-lg-block">
                        <a class="nav-link bookmark-star">
                            <i class="ficon text-warning" data-feather="star"></i>
                        </a>
                        <div class="bookmark-input search-input">
                            <div class="bookmark-input-icon"><i data-feather="search"></i></div>
                            <input class="form-control input" type="text" placeholder="Bookmark" tabindex="0" data-search="search">
                            <ul class="search-list search-list-bookmark"></ul>
                        </div>
                    </li> -->
                    <li class="nav-item">
                        <a class="navbar-brand" href="#">
                            <img :src="url('static/uni/agency-logo.png')" height="50">
                        </a>
                    </li>
                    <li class="nav-item ml-2">
                        <div class="user-nav">
                            <!-- <span class="user-name font-weight-bolder" v-html="cn(store_auth_user, 'name', 'User')"></span> -->
                            <span class="user-name font-weight-bolder" v-html="acc.name"></span>
                            <br>
                            <span class="badge badge-primary badge-pill user-status agent_code" v-tooltip="'Agent Code'" v-html="acc.code"></span>
                        </div>
                    </li>
                </ul>
            </div>
            <ul class="nav navbar-nav align-items-center ml-auto">
                <!-- <li class="nav-item d-none d-lg-block"><a class="nav-link nav-link-style"><i class="ficon" data-feather="moon"></i></a></li>
                <li class="nav-item nav-search"><a class="nav-link nav-link-search"><i class="ficon" data-feather="search"></i></a>
                    <div class="search-input">
                        <div class="search-input-icon"><i data-feather="search"></i></div>
                        <input class="form-control input" type="text" placeholder="Explore Vuexy..." tabindex="-1" data-search="search">
                        <div class="search-input-close"><i data-feather="x"></i></div>
                        <ul class="search-list search-list-main"></ul>
                    </div>
                </li> -->

                <li class="nav-item mr-25" style="position: relative;">
                    <router-link to="/user-guideline" class="user_guide_menu" v-tooltip="'User Guideline'">
                    <!-- <a href="javascript:void(0)" class="user_guide_menu" v-tooltip="'User Guideline'"> -->
                        <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 16 16"><path fill="currentColor" d="M15 4.7V4a6.8 6.8 0 0 0-4.484-1.999a2.84 2.84 0 0 0-2.513.995a3.02 3.02 0 0 0-2.515-.995A6.8 6.8 0 0 0 1 4v.7L0 5v10l6.7-1.4l.3.4h2l.3-.4L16 15V5zm-9.52 6.61a8.2 8.2 0 0 0-3.526.902L2 4.42A5.22 5.22 0 0 1 5.369 3a4.55 4.55 0 0 1 2.159.701l-.019 7.869a6.6 6.6 0 0 0-2.039-.259zm8.52.88a8.1 8.1 0 0 0-3.468-.88l-.161-.002c-.66 0-1.297.096-1.899.274l.047-7.902a4.5 4.5 0 0 1 2.096-.679a5.22 5.22 0 0 1 3.386 1.422l-.003 7.768z"/></svg>
                    <!-- </a> -->
                     </router-link>
                </li>
                
                <li class="nav-item dropdown dropdown-notification mr-25" :class="flag.show_bell_notifications?'show':''" v-click-outside="close_bell_notifications">
                    <a class="nav-link" href="javascript:void(0);" data-toggle="dropdown" v-on:click="toggle_bell_notifications()">
                        <i class="ficon" data-feather="bell"></i>
                        <span class="badge badge-pill badge-danger badge-up" v-if="list.bell_notifications.length" v-html="list.bell_notifications.length"></span>
                    </a>
                    <ul class="dropdown-menu dropdown-menu-media dropdown-menu-right bell-n-dropdown-ul" :class="flag.show_bell_notifications?'show':''">
                        <li class="dropdown-menu-header">
                            <div class="dropdown-header d-flex">
                                <h4 class="notification-title mb-0 mr-auto">Notifications</h4>
                                <div class="badge badge-pill badge-light-primary" v-if="list.bell_notifications.length">
                                    <span v-html="list.bell_notifications.length"></span> New
                                </div>
                            </div>
                        </li>
                        <li class="scrollable-container media-list" v-if="list.bell_notifications.length">
                            <a class="d-flex" v-for="(row, index) in list.bell_notifications" :key="row.id">
                                <div class="media d-flex align-items-start">
                                    <div class="media-left">
                                        <div class="avatar bg-light-primary">
                                            <div class="avatar-content" v-html="avatarText(row.creator)"></div>
                                        </div>
                                    </div>
                                    <div class="media-body">
                                        <p class="media-heading">
                                            <span class="font-weight-bolder text-uclan" v-if="row.url" v-on:click="goto_url(row)" v-html="row.subject+': '"></span>
                                            <span class="font-weight-bolder" v-else v-html="row.subject+': '"></span>
                                            <span v-html="row.msg"></span>
                                        </p>
                                        <small class="notification-text">
                                            <i class="fa fa-user-o mr-25" aria-hidden="true"></i>
                                            <span v-html="row.creator"></span>
                                            <i class="fa fa-clock-o mx-25" aria-hidden="true"></i>
                                            <span v-html="dNow(row.created_at)"></span>
                                            <!-- &bull;
                                            <span v-html="dDate(row.created_at, 'hh:mm A')"></span> -->
                                        </small>
                                    </div>
                                    <div class="my-auto">                               
                                        <i class="fa fa-times-circle text-secondary font-size-18" aria-hidden="true" v-tooltip="'Close'" v-on:click="bell_notification_mark_as_read(row.id, index)"></i>
                                    </div>
                                </div>
                            </a>
                        </li>
                        <li v-show="!list.bell_notifications.length" class="dropdown-menu-footer text-center">
                            <strong>
                                <i class="fa fa-exclamation-triangle text-warning" aria-hidden="true"></i>
                                Found no notification.
                            </strong>
                        </li>
                        <!-- <li class="dropdown-menu-footer"><a class="btn btn-primary btn-block" href="javascript:void(0)">Read all notifications</a></li> -->
                    </ul>
                </li>
                <li class="nav-item dropdown dropdown-user" :class="flag.acc_dropdown?'show':''">
                    <a class="nav-link dropdown-toggle dropdown-user-link" id="dropdown-user" href="javascript:void(0);" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" v-on:click="acc_dropdown()">

                        <div class="user-nav d-sm-flex d-none" style="display: none !important;">
                            <!-- <span class="user-name font-weight-bolder" v-html="cn(store_auth_user, 'name', 'User')"></span> -->
                            <span class="user-name font-weight-bolder" v-html="acc.name"></span>
                            <span class="user-status" v-tooltip="'Agent Code'" v-html="acc.code"></span>
                        </div>
                        
                        <span class="avatar right_user_part">
                            <!-- <img class="round" :src="url('static/vuexy/images/no_avatar.png')" alt="avatar" height="40" width="40"/> -->
                            
                                <div v-if="agent_company_logo_url" class="avatar  bg-light-primary">
                                    <img :src="agent_company_logo_url" width="50" height="50" alt="Logo">
                                </div>
                                <div v-else class="avatar  bg-light-primary">
                                    <span class="avatar-content user_text_div" v-html="avatarText(cn(store_auth_user, 'name', 'User'))"></span>
                                </div>
                            
                            <!-- <span class="avatar-status-online"></span> -->
                        </span>
                    </a>
                    <div style="width: 200px;" class="dropdown-menu dropdown-menu-right" :class="flag.acc_dropdown?'show':''" aria-labelledby="dropdown-user">
                        <!-- <a class="dropdown-item" href="javascript:void(0)"><i class="mr-50" data-feather="user"></i> Profile</a> -->
                        <!-- <a v-on:click="logout()" class="dropdown-item" href="javascript:void(0)"><i class="mr-50" data-feather="power"></i> Logout</a> -->
                        <router-link v-if="sub_agent_status !=1" to="/settings/general-settings" class="dropdown-item">
                            <i class="fa fa-user mr-50" aria-hidden="true"></i> Profile
                        </router-link>
                      


                        <a v-on:click="changePasword()" class="dropdown-item" href="javascript:void(0)"><i class="mr-50" data-feather="key"></i> Change Password</a>

                        <router-link v-if="check_branch > 0" to="/settings/branch-settings" class="dropdown-item">
                            <i class="fa fa-sitemap mr-50" aria-hidden="true"></i> Branch 
                        </router-link>
                        <router-link to="/logout" class="dropdown-item">
                            <i class="mr-50" data-feather="power"></i> Logout
                        </router-link>
                    </div>
                </li>
            </ul>
        </div>
    </nav>
    <ul class="main-search-list-defaultlist d-none">
        <li class="d-flex align-items-center"><a href="javascript:void(0);">
                <h6 class="section-label mt-75 mb-0">Files</h6>
            </a></li>
        <li class="auto-suggestion"><a class="d-flex align-items-center justify-content-between w-100" href="app-file-manager.html">
                <div class="d-flex">
                    <div class="mr-75"><img src="app-assets/images/icons/xls.png" alt="png" height="32"></div>
                    <div class="search-data">
                        <p class="search-data-title mb-0">Two new item submitted</p><small class="text-muted">Marketing Manager</small>
                    </div>
                </div><small class="search-data-size mr-50 text-muted">&apos;17kb</small>
            </a></li>
        <li class="auto-suggestion"><a class="d-flex align-items-center justify-content-between w-100" href="app-file-manager.html">
                <div class="d-flex">
                    <div class="mr-75"><img src="app-assets/images/icons/jpg.png" alt="png" height="32"></div>
                    <div class="search-data">
                        <p class="search-data-title mb-0">52 JPG file Generated</p><small class="text-muted">FontEnd Developer</small>
                    </div>
                </div><small class="search-data-size mr-50 text-muted">&apos;11kb</small>
            </a></li>
        <li class="auto-suggestion"><a class="d-flex align-items-center justify-content-between w-100" href="app-file-manager.html">
                <div class="d-flex">
                    <div class="mr-75"><img src="app-assets/images/icons/pdf.png" alt="png" height="32"></div>
                    <div class="search-data">
                        <p class="search-data-title mb-0">25 PDF File Uploaded</p><small class="text-muted">Digital Marketing Manager</small>
                    </div>
                </div><small class="search-data-size mr-50 text-muted">&apos;150kb</small>
            </a></li>
        <li class="auto-suggestion"><a class="d-flex align-items-center justify-content-between w-100" href="app-file-manager.html">
                <div class="d-flex">
                    <div class="mr-75"><img src="app-assets/images/icons/doc.png" alt="png" height="32"></div>
                    <div class="search-data">
                        <p class="search-data-title mb-0">Anna_Strong.doc</p><small class="text-muted">Web Designer</small>
                    </div>
                </div><small class="search-data-size mr-50 text-muted">&apos;256kb</small>
            </a></li>
        <li class="d-flex align-items-center"><a href="javascript:void(0);">
                <h6 class="section-label mt-75 mb-0">Members</h6>
            </a></li>
        <li class="auto-suggestion"><a class="d-flex align-items-center justify-content-between py-50 w-100" href="app-user-view.html">
                <div class="d-flex align-items-center">
                    <div class="avatar mr-75"><img src="app-assets/images/portrait/small/avatar-s-8.jpg" alt="png" height="32"></div>
                    <div class="search-data">
                        <p class="search-data-title mb-0">John Doe</p><small class="text-muted">UI designer</small>
                    </div>
                </div>
            </a></li>
        <li class="auto-suggestion"><a class="d-flex align-items-center justify-content-between py-50 w-100" href="app-user-view.html">
                <div class="d-flex align-items-center">
                    <div class="avatar mr-75"><img src="app-assets/images/portrait/small/avatar-s-1.jpg" alt="png" height="32"></div>
                    <div class="search-data">
                        <p class="search-data-title mb-0">Michal Clark</p><small class="text-muted">FontEnd Developer</small>
                    </div>
                </div>
            </a></li>
        <li class="auto-suggestion"><a class="d-flex align-items-center justify-content-between py-50 w-100" href="app-user-view.html">
                <div class="d-flex align-items-center">
                    <div class="avatar mr-75"><img src="app-assets/images/portrait/small/avatar-s-14.jpg" alt="png" height="32"></div>
                    <div class="search-data">
                        <p class="search-data-title mb-0">Milena Gibson</p><small class="text-muted">Digital Marketing Manager</small>
                    </div>
                </div>
            </a></li>
        <li class="auto-suggestion"><a class="d-flex align-items-center justify-content-between py-50 w-100" href="app-user-view.html">
                <div class="d-flex align-items-center">
                    <div class="avatar mr-75"><img src="app-assets/images/portrait/small/avatar-s-6.jpg" alt="png" height="32"></div>
                    <div class="search-data">
                        <p class="search-data-title mb-0">Anna Strong</p><small class="text-muted">Web Designer</small>
                    </div>
                </div>
            </a></li>
    </ul>
    <ul class="main-search-list-defaultlist-other-list d-none">
        <li class="auto-suggestion justify-content-between"><a class="d-flex align-items-center justify-content-between w-100 py-50">
                <div class="d-flex justify-content-start"><span class="mr-75" data-feather="alert-circle"></span><span>No results found.</span></div>
            </a></li>
    </ul>
    <!-- END: Header-->

    <!-- BEGIN: Main Menu-->
    <div class="horizontal-menu-wrapper">
        <!-- <div class="header-navbar navbar-expand-sm navbar navbar-horizontal floating-nav navbar-light navbar-shadow menu-border container-xxl" role="navigation"> -->
        <div class="header-navbar navbar-expand-sm navbar navbar-horizontal floating-nav navbar-light navbar-shadow menu-border" role="navigation">
            <div class="navbar-header">
                <ul class="nav navbar-nav flex-row">
                    <li class="nav-item mr-auto">
                        <a class="navbar-brand" href="#">
                            <img src="images/logo.png" width="70">
                        </a>
                    </li>
                    <li class="nav-item nav-toggle"><a class="nav-link modern-nav-toggle pr-0" data-toggle="collapse"><i class="d-block d-xl-none text-primary toggle-icon font-medium-4" data-feather="x"></i></a></li>
                </ul>
            </div>
            <div class="shadow-bottom"></div>
            <!-- Horizontal menu content-->
            <div class="navbar-container main-menu-content" data-menu="menu-container">
                <!-- include includes/mixins-->
                <ul class="nav navbar-nav" id="main-menu-navigation" data-menu="menu-navigation">
                    <template v-for="row in list.nav_menu">
                        <li v-if="row.check_sub_agent !=1 || sub_agent_status !=1" class="dropdown nav-item" data-menu="dropdown" :key="row.id" v-on:mouseover="open_nav_submenu(row)" v-on:mouseleave="close_nav_submenu(row)" :class="active_nav(row)">
                            <router-link v-if="row.url" :class="row.children?'dropdown-toggle':''" class="nav-link d-flex align-items-center" :to="row.url">
                                <i v-if="!row.icon_type" :data-feather="row.icon"></i>
                                <i v-if="row.icon_type=='fontawesome'" :class="row.icon"></i>
                                <span v-html="row.label"></span>
                                <span class="badge badge-pill badge-danger ml-50" v-if="row.id == 'comm_with_uni' && unreadCommentsCount"  v-html="unreadCommentsCount"></span>
                            </router-link>
                            <a v-else :class="row.children?'dropdown-toggle':''" class="nav-link d-flex align-items-center" href="javascript:void(0)">
                                <i v-if="!row.icon_type" :data-feather="row.icon"></i>
                                <i v-if="row.icon_type=='fontawesome'" :class="row.icon"></i>
                                <span v-html="row.label"></span>
                            </a>
                            <ul class="dropdown-menu" :class="row.class ? 'settings-dropdown' : ''" v-if="row.children">
                                <li v-for="inner_row in row.children" :key="inner_row.id">
                                    <router-link v-if="inner_row.url" class="dropdown-item d-flex align-items-center" :to="inner_row.url" :class="active_nav(inner_row)">
                                        <i v-if="!inner_row.icon_type" :data-feather="inner_row.icon"></i>
                                        <i v-if="inner_row.icon_type=='fontawesome'" :class="inner_row.icon"></i>
                                        <span v-html="inner_row.label"></span>
                                    </router-link>
                                    <a v-else class="dropdown-item d-flex align-items-center" href="javascript:void(0)">
                                        <i :data-feather="inner_row.icon"></i>
                                        <span v-html="inner_row.label"></span>
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </template>
                </ul>
            </div>
        </div>
    </div>
    <!-- END: Main Menu-->

    <!-- BEGIN: Content-->
    <div class="app-content content ">
        <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <!-- <div class="content-wrapper container-xxl p-0"> -->
        <div class="content-wrapper">

            <!-- <div class="content-header row">
                <div class="col">
                    <h2 class="content-header-title float-left mb-0">Agency</h2>
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item active">List</li>
                        </ol>
                    </div>
                </div>
            </div> -->

            <div class="content-body" style="min-height: 70vh;">
                <slot></slot>
            </div>

        </div>
    </div>
    <!-- END: Content-->

    <div class="sidenav-overlay"></div>
    <div class="drag-target"></div>

    <!-- BEGIN: Footer-->
    <footer class="footer footer-static footer-light">
        <p class="clearfix mb-0 text-center"><span class=" d-block d-md-inline-block mt-25">Copyright &copy; The University Of Sheffield {{ dYear(new Date()) }}</span></p>
    </footer>
    <button class="btn btn-primary btn-icon scroll-top" type="button"><i data-feather="arrow-up"></i></button>
    <!-- END: Footer-->

</section>
</template>

<script>
import feather from 'feather-icons';

export default{
    name: 'MainLayout',
    props:[],
    components:{},
    data:()=>{
        return {
            // unreadCommentsCount: 0,
            check_branch :0,
            list:{
                nav_menu:[
                    {
                        id:'dashboard',
                        label:'Dashboard',
                        icon: 'home',
                        url: '/dashboard',
                        expand: false,
                        children: null,
                        check_sub_agent:0
                    },
                    // {
                    //    id:'manage_student',
                    //     label:'Manage Students',
                    //     icon:'users',
                    //     url:'/student-list',
                    //     expand:false,
                    //     active_routes: [
                    //         'AddStudent',
                    //         'AddNewMultipleStudent'
                    //     ], 
                    // },
                    // {
                    //     id:'manage_student',
                    //     label:'Manage Students',
                    //     icon:'users',
                    //     url:'/student-list',
                    //     expand:false,
                    //     active_routes: [
                    //         'AddStudent',
                    //         'AddNewMultipleStudent'
                    //     ],
                    //     children:[
                    //         {
                    //             id:'student_list',
                    //             label:'Student Lists',
                    //             url:'/student-list',
                    //             icon:'plus-square'
                    //         },{
                    //             id:'add_student',
                    //             label:'Add Student',
                    //             url:'/add-student',
                    //             icon:'users'
                    //         },{
                    //             id:'add_new_multiple_student',
                    //             label:'Add Multiple Student',
                    //             url:'/add-new-multiple-student',
                    //             icon:'users'
                    //         }
                    //     ]
                    // },
                    {
                        id:'submit_to_university',
                        label:'Submit Your Claim',
                        icon:'send',
                        url:'/submit-to-university',
                        expand:false,
                        children: null,
                        active_routes: [
                            'StudentListByIntake',
                        ],
                        check_sub_agent:0
                    },
                    {
                        id:'view_all_claims',
                        label:'View all Claims',
                        icon:'fa fa-paper-plane',
                        icon_type: 'fontawesome',
                        url:'/view-all-claims',
                        expand:false,
                        children: null,
                        active_routes: [
                            'IntakeWiseStudentList'
                        ],
                        check_sub_agent:1
                    },
                    
                    // {
                    //     id:'future_payment_student_list',
                    //     label:'For Future Payment',
                    //     icon_type: 'fontawesome',
                    //     icon:'fa fa-credit-card',
                    //     url:'/future-payment-student-list',
                    //     expand:false,
                    //     children: null
                    // },
                    {
                        id: 'comm_with_uni',
                        label: 'Inbox',
                        icon_type: 'fontawesome',
                        icon:'fa fa-comment-o',
                        url:'/communications',
                        expand:false,
                        children: null,
                        check_sub_agent:1
                    },
                    // {
                    //     id:'general_information_setting',
                    //     label:'',
                    //     icon:'settings',
                    //     url: null,
                    //     expand:false,
                    //     class: 'ml-auto',
                    //     children: [
                    //         {
                    //             id:'general_account',
                    //             label:'Agent Details',
                    //             url:'/settings/general-settings',
                    //             icon_type: 'fontawesome',
                    //             icon:'fa fa-id-card-o',
                    //         },
                    //         {
                    //             id:'bank_account',
                    //             label:'Bank Account',
                    //             icon_type: 'fontawesome',
                    //             icon:'fa fa-university',
                    //             url:'/settings/bank-list',
                    //         }
                    //     ]
                    // },
                    /* {
                        id:'invoices',
                        label:'Invoices',
                        icon:'file-text',
                        url:null,
                        expand:false,
                        children:[
                            {
                                id:'pending_invoice',
                                label:'Pending Invoice',
                                url:'',
                                icon:'file-text'
                            },{
                                id:'confirmed_invoice',
                                label:'Confirmed Invocie',
                                icon:'file-text',
                                url:''
                            },{
                                id:'paid_invoice',
                                label:'Paid Invoice',
                                icon:'file-text',
                                url:''
                            },{
                                id:'invoice_report',
                                label:'Invoice Report',
                                icon:'file-text',
                                url:''
                            }s
                        ]
                    } */

                ],
                bell_notifications:[]
            },
            flag:{
                acc_dropdown: false,
                show_bell_notifications: false
            }
        };
    },
    created: function(){
        this.setup_urls();
        this.get_bell_notifications();
        this.get_comment_count();
    },
    methods:{
        acc_dropdown: function(){
            this.flag.acc_dropdown=!this.flag.acc_dropdown;
        },
		changePasword: function(){
			this.$router.push('/change-password');
		},
		logout: async function(){

			let ref=this;

            var jq=ref.jq();
            var url=ref.url('api/v1/agent/auth/logout');

            try{
                await jq.post(url);
            }catch(err){
                console.log(err);
            }

            localStorage.removeItem('agent_api_token')
			ref.$router.push('/');

		},
        open_nav_submenu: function(row){
            var ref=this;
            ref.list.nav_menu.forEach(each=>{
                each.expand=false;
            });
            row.expand=true;
        },
        close_nav_submenu: function(row){
            row.expand=false;
        },
        active_nav: function(row){

            var ref=this;
            var route_path=ref.$route.path
            var route_name = ref.$route.name
            // console.log('route_name', route_name)
            var classes='';

            if (row.class) {
                classes += row.class;
            }

            if(row.url && route_path==row.url){
                classes += ' active';
            }else if(row.children){

                var children_urls=row.children.map(each=>{
                    return each.url;
                });

                if(children_urls.includes(route_path)) classes +=' active';

            }
            
            if (row.active_routes && row.active_routes.length) {
                var route_matched = row.active_routes.find(item => item == route_name)
                if (route_matched) {
                    classes += ' active';
                }
            }

            if(row.expand) classes+=' show';

            return classes;

        },
        toggle_bell_notifications: function(){

            this.flag.show_bell_notifications=!this.flag.show_bell_notifications;

            if(this.flag.show_bell_notifications){
                this.get_bell_notifications();
            }

        },
        get_bell_notifications: async function(){

            var ref=this;
            var jq=this.jq();
            var url=ref.url('api/v1/agent/ajax/get_bell_notifications');

            try {
                var res=await jq.get(url);
                ref.list.bell_notifications=res.data.bell_notifications;
                ref.check_branch=res.data.agent_branches;
                localStorage.setItem('check_branch', JSON.stringify(ref.check_branch));
                console.log('brachCount', ref.check_branch);
                
                
            }catch(err){
                ref.alert(ref.err_msg(err), 'error');
            }

        },
        close_bell_notifications: function(){
            this.flag.show_bell_notifications=false;
        },
        bell_notification_mark_as_read: async function(id, index){
            
            var ref=this;
            var jq=ref.jq();
            var url=ref.url('api/v1/agent/ajax/set_notification_read_at');
            
            try{

                await jq.post(url, {
                    notification_id: id
                });
                console.log(index)

                // ref.remove_row(ref.list.bell_notifications, index);
                this.get_bell_notifications()

            }catch(err){
                ref.alert(ref.err_msg(err), 'error');
            }

        },
        goto_comms: function(){
            this.$router.push('/communications');
        },
        goto_url: function(row){
            if (row.url) {
                // window.location.href = row.url;
                window.open(row.url, '_blank')
            }
        },
        get_comment_count: async function(){

            var ref=this;
            var jq=this.jq();
            var url=ref.url('api/v1/agent/ajax/get_unread_comment_count_data');

            try {
                var res=await jq.get(url);
                // this.unreadCommentsCount=res.data.unread_comments_count;
                ref.$store.commit('siteInfo', {
                    key: 'unreadCommentsCount',
                    val: res.data.unread_comments_count
                });
            }catch(err){
                ref.alert(ref.err_msg(err), 'error');
            }

        },
    },
    computed:{
        agent_company:function(){
            return this.cn(this.$store.state, 'site.agent_company', null);
        },
        agent_company_logo_url:function(){
            return this.cn(this.$store.state, 'site.agent_company_logo_url', null);
        },
        store_auth_user:function(){
            return this.cn(this.$store.state, 'site.auth_user', null);
        },
        unreadCommentsCount: function () {
            return this.$store.state.site.unreadCommentsCount
        },
        sub_agent_status:function(){
            return this.cn(this.$store.state, 'site.sub_agent_status', null);
        },
        agent_branch: function(){
            return this.cn(this.$store.state, 'site.agent_branch', null);
        },
        acc: function(){

            let rr={
                name: 'Loading...',
                code: 'Loading...',
            }

            let agent_branch=this.cn(this.$store.state, 'site.agent_branch', null);
            let agent=this.cn(this.$store.state, 'site.agent_company', null);

            if(agent_branch){
                rr.name=agent.name;
                rr.code=`${agent_branch.branch_name} (${agent_branch.branch_banner_code})`;
            }else if(agent){
                rr.name=agent.name;
                rr.code=agent.agent_code;
            }

            return rr;

        }
    },
    mounted: function(){

        var jq=this.jq();
        jq('body').removeClass('blank-page');
        feather.replace();
        
    }
}
</script>
<style type="text/css">

.bookmark-wrapper{
    margin-left:15px;
}    
.navbar-nav{
    margin-right: 7px;
}
/* .avatar .avatar-content {
    width: 40px !important;
    height: 40px !important;
    font-size: 1.2rem!important;
} */
.text-uclan {
    color: rgb(4, 126, 175) !important;
    font-weight: 500;
}

.text-primary {
    color: #047eaf !important;
}

.bell-n-dropdown-ul{
    height: auto;
    overflow: auto;
}
.b-avatar {
    display: inline-flex !important;
    align-items: center !important;
    justify-content: center !important;
    vertical-align: middle !important;
    flex-shrink: 0 !important;
    width: 2.5rem !important;
    height: 2.5rem !important;
    font-size: inherit !important;
    font-weight: 400 !important;
    line-height: 1 !important;
    max-width: 100% !important;
    max-height: auto !important;
    text-align: center !important;
    overflow: visible !important;
    position: relative !important;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}

.settings-dropdown {
    left: -140px !important
}
.comment-wrapper {
  padding-left: 25px;
}

.right_user_part .avatar img{
    width: 35px !important;
    height: 35px !important;
}
.right_user_part .avatar .user_text_div{
    width: 35px !important;
    height: 35px !important;
    background: #dfdfef;
    color: #6966af;
    letter-spacing: .5px;
    font-weight: 600;
    line-height: 35px;
}
.navbar-container .nav-item .agent_code{
    border-radius: .25rem;
    background: #e6e2ff !important;
    color: #472884;
}
.header-navbar .navbar-container ul.navbar-nav li .user_guide_menu .fa-book{
    padding: .5rem;
    font-size: 1.15rem !important;
    color: #6e6b7b;
}
.header-navbar .navbar-container ul.navbar-nav li .user_guide_menu {
    color: #6e6b7b !important;
}
.header-navbar .navbar-container ul.navbar-nav li .user_guide_menu:hover {
    color: #7367f0 !important;
}
</style>